import React, { useState } from 'react';
import { Layout, Col, Row, Input, Form, Button, Card, Typography, InputNumber, Select, message, Spin, Icon } from 'antd';
import styled from "styled-components";

import { landsAlignments, landsBuildability, lands } from '../../connections/feathers';

import S3File from '../s3/s3-file';
import { useEffect } from 'react';

const { Content } = Layout;
const { Title } = Typography;
const { Option } = Select;

const DivContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 88%;
    height: 100%;
    background-color: rgba(96, 91, 82, 0.7);
    position: absolute;
    z-index: 9;

    & h3, h2 {
        text-align: center !important; 
        color: white !important; 
    }
`

export const SecondEdit = (props) => {

    const [loadingScreen, setLoadingScreen] = useState(false);

    const [disabled, setDisabled] = useState({
        disabled1: false,
        disabled2: false,
    });

    const { disabled1, disabled2 } = disabled;

    const [form, setForm] = useState({
        alignment_neighborhood: '',
        alignment_piece: '',
        alignment_list: [],
        buildability_neighborhood: '',
        buildability_piece: '',
        buildability_poligon: '',
        buildability_treatment: '',
        buildability_modality: '',
        buildability_list: [
        ],
        land_use_path: null,
    });

    const { alignment_neighborhood, alignment_piece, buildability_neighborhood, buildability_piece, buildability_poligon, buildability_treatment, buildability_modality, land_use_path } = form;

    const handleInputChange = ({ value, name }) => {
        setForm({ ...form, [name]: value });
    }

    const [arrAlignment, setArrAlignment] = useState({
        street_name: '',
        profile_name: '',
        hierarchy: '',
        lb_lc: ''
    });

    const { street_name, profile_name, hierarchy, lb_lc } = arrAlignment;

    const [arrBuildability, setArrBuildability] = useState({
        type: '',
        range: '',
        max_density: 0,
        max_height: 0,
    });

    const { type, range, max_density, max_height } = arrBuildability;

    const handleInputChangeList = ({ name, value }, key) => {
        if (key === 1) {
            setArrAlignment({ ...arrAlignment, [name]: value });
        } else {
            setArrBuildability({ ...arrBuildability, [name]: value });
        }
    }

    const [showCardList, setShowCardList] = useState({
        list1: false,
        list2: false,
    });

    const { list1, list2 } = showCardList;

    const changeShowList = (key) => {
        if (key === 1) {
            setShowCardList({
                ...showCardList,
                list1: !list1,
            });
            setArrAlignment({
                street_name: '',
                profile_name: '',
                hierarchy: '',
                lb_lc: '',
            })
        } else {
            setShowCardList({
                ...showCardList,
                list2: !list2,
            });
            setArrBuildability({
                type: '',
                range: '',
                max_density: 0,
                max_height: 0,
            });
        }
    }

    const saveList = async (key) => {
        if (key === 1) {
            if (street_name === '' || profile_name === '' || hierarchy ==='' || lb_lc === '') {
                alert('Todos los campos de "Item de Alineamiento" son obligatorios');
                return;
            }
            // let id = form.alignment_list.length + 1;
            // if (id === undefined) id = 0;
            // setArrAlignment({ ...arrAlignment, id });
            // const auxArr = [...form.alignment_list, arrAlignment];

            await landsAlignments.create({ street_name, profile_name, hierarchy, lb_lc, land_id: props.id }).then(res => setForm({ ...form, alignment_list: form.alignment_list.concat(res) }));
            changeShowList(1);
            message.success('Se ha guardado exitosamente el item de alineamiento');
        } else {
            if (type === '' || range === '' || max_density < 0 || max_height < 0) {
                alert('Todos los campos de "Item de  Edificabilidad" son obligatorios y revise que los campos numericos sean mayor de cero.');
                return;
            }

            await landsBuildability.create({ type, range, max_density, max_height, land_id: props.id }).then(res => setForm({ ...form, buildability_list: form.buildability_list.concat(res) }));
            changeShowList(2);
            message.success('Se ha guardado exitosamente el item de edificabilidad')
        }
        console.log({ form })
    }

    const deleteItemList = async (key, id) => {
        console.log({ key, id })
        if (key === 1) {
            setDisabled({ ...disabled, disabled1: true });
            await landsAlignments.remove(id).then(res => setForm({ ...form, alignment_list: form.alignment_list.filter(item => item.id !== res.id) }));
            setDisabled({ ...disabled, disabled1: false });
            message.success('Se ha borrado exitosamente el item de alineamiento');
        } else {
            setDisabled({ ...disabled, disabled2: true });
            await landsBuildability.remove(id).then(res => setForm({ ...form, buildability_list: form.buildability_list.filter(item => item.id !== res.id) }));
            setDisabled({ ...disabled, disabled2: false });
            message.success('Se ha borrado exitosamente el item de edificabilidad')
        }
    }

    const saveChanges = async () => {
        await lands.patch(props.id, { alignment_neighborhood, alignment_piece, buildability_neighborhood, buildability_piece, buildability_poligon, buildability_treatment, buildability_modality, land_use_path })
            .then(res => {
                console.log(res)
                message.success('Se ha guardado la informacion exitosamente')
            })
            .catch((err) => console.log("catch===>", err));
    }

    const getInfoUpdate = async (id) => {
        setLoadingScreen(true);
        const dataLand = await lands.get(id)
            .catch((err) => console.log("catch===>", err));

        const dataAlignments = await landsAlignments
            .find({ query: { land_id: id } })
            .then((res) => res.data)
            .catch((err) => console.log("catch===>", err));

        const buildability = await landsBuildability
            .find({ query: { land_id: id } })
            .then((res) => res.data)
            .catch((err) => console.log("catch===>", err));

        setForm({
            ...form,
            alignment_neighborhood: dataLand.alignment_neighborhood ? dataLand.alignment_neighborhood : '',
            alignment_piece: dataLand.alignment_piece ? dataLand.alignment_piece : '',
            buildability_neighborhood: dataLand.buildability_neighborhood ? dataLand.buildability_neighborhood : '',
            buildability_piece: dataLand.buildability_piece ? dataLand.buildability_piece : '',
            buildability_poligon: dataLand.buildability_poligon ? dataLand.buildability_poligon : '',
            buildability_treatment: dataLand.buildability_treatment ? dataLand.buildability_treatment : '',
            buildability_modality: dataLand.buildability_modality ? dataLand.buildability_modality : '',
            land_use_path: dataLand.land_use_path ? dataLand.land_use_path : '',
            alignment_list: dataAlignments.length > 0 ? dataAlignments : [],
            buildability_list: buildability.length > 0 ? buildability : [],
        })
        setLoadingScreen(false);
    }

    useEffect(() => {
        if (props.id) {
            getInfoUpdate(props.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.id]);

    return (
        <>
            {
                loadingScreen &&
                <DivContent>
                    <Spin size="large" />
                    <h3>Cargando...</h3>
                </DivContent>
            }
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                // onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Layout>
                    <Content>
                        <Row>
                            <Col span={24}>
                                <h3>Alineamiento</h3>
                            </Col>
                        </Row>
                        <Row justify="space-around" align="top">
                            <Col span={11}>
                                <Input
                                    placeholder="Barrio"
                                    name='alignment_neighborhood'
                                    value={alignment_neighborhood}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                            <Col span={11}>
                                <Input
                                    placeholder="Pieza"
                                    name='alignment_piece'
                                    value={alignment_piece}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h3>Lista de Alineamiento</h3>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {
                                form.alignment_list !== undefined && (form.alignment_list.map((item, idx) => {
                                    return (
                                        <Card
                                            key={idx}
                                            title={`Alineamiento ${idx + 1}`}
                                            bordered={false}
                                            extra={
                                                <Button
                                                    type="dashed"
                                                    danger
                                                    shape="circle"
                                                    icon="delete"
                                                    style={{ marginLeft: "15px" }}
                                                    disabled={disabled1}
                                                    onClick={() => deleteItemList(1, item.id)}
                                                ></Button>
                                            }
                                            style={{
                                                width: "80%",
                                                minWidth: "300px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Row justify="space-around" align="middle">
                                                <Col span={24}>
                                                    <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                        <Col span={5}>
                                                            <Title level={4}>Nom. Via:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.street_name}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>Nom. Perfil:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.profile_name}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>Jerarquía:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.hierarchy}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>LB LC:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.lb_lc}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                }))
                            }
                        </Row>
                        {
                            list1 && (
                                <div className="site-card-border-less-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
                                    <Card
                                        title="Item de Alineamiento"
                                        bordered={false}
                                        style={{
                                            width: 600,
                                        }}
                                    >
                                        <Row justify="space-around" align="middle">
                                            <Col span={24}>
                                                <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={11}>
                                                        <Input
                                                            placeholder="Nom. Via"
                                                            name='street_name'
                                                            value={street_name}
                                                            onChange={(e) => handleInputChangeList(e.target, 1)}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <Input
                                                            placeholder="Nom. Perfil"
                                                            name='profile_name'
                                                            value={profile_name}
                                                            onChange={(e) => handleInputChangeList(e.target, 1)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={11}>
                                                        <Input
                                                            placeholder="Jerarquía"
                                                            name='hierarchy'
                                                            value={hierarchy}
                                                            onChange={(e) => handleInputChangeList(e.target, 1)}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <Input
                                                            placeholder="LB LC"
                                                            name='lb_lc'
                                                            value={lb_lc}
                                                            onChange={(e) => handleInputChangeList(e.target, 1)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row justify="space-evenly" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={12}>
                                                        <Button
                                                            type="primary"
                                                            primary
                                                            style={{ marginLeft: "15px" }}
                                                            onClick={() => saveList(1)}
                                                        >
                                                            GUARDAR
                                                        </Button>
                                                        <Button
                                                            type="dashed"
                                                            danger
                                                            style={{ marginLeft: "15px" }}
                                                            onClick={() => changeShowList(1)}
                                                        >
                                                            CANCELAR
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )

                        }
                        <Row>
                            <Col span={24}>
                                <Button
                                    type="dashed"
                                    primary
                                    icon="plus-circle"
                                    style={{ margin: "10px" }}
                                    onClick={() => changeShowList(1)}
                                >
                                    AGREGAR
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h3>Edificabilidad</h3>
                            </Col>
                        </Row>
                        <Row justify="space-around" align="center" style={{ marginBottom: "5px" }}>
                            <Col span={11}>
                                <Input
                                    placeholder="Barrio"
                                    name='buildability_neighborhood'
                                    value={buildability_neighborhood}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                            <Col span={11}>
                                <Input
                                    placeholder="Pieza"
                                    name='buildability_piece'
                                    value={buildability_piece}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                        </Row>
                        <Row justify="space-around" align="center" style={{ marginBottom: "5px" }}>
                            <Col span={11}>
                                <Input
                                    placeholder="Poligono"
                                    name='buildability_poligon'
                                    value={buildability_poligon}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                            <Col span={11}>
                                <Input
                                    placeholder="Tratamiento"
                                    name='buildability_treatment'
                                    value={buildability_treatment}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                        </Row>
                        <Row justify="space-around" align="center" style={{ marginBottom: "5px" }}>
                            <Col span={11}>
                                <Input
                                    placeholder="Modalidad"
                                    name='buildability_modality'
                                    value={buildability_modality}
                                    onChange={(e) => handleInputChange(e.target)}
                                />
                            </Col>
                            <Col span={11}>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h3>Lista de Edificabilidad</h3>
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                            {
                                form.buildability_list !== undefined && (form.buildability_list.map((item, idx) => {
                                    return (
                                        <Card
                                            key={idx}
                                            title={`Edificabilidad ${idx + 1}`}
                                            bordered={false}
                                            extra={
                                                <Button
                                                    type="dashed"
                                                    danger
                                                    shape="circle"
                                                    icon="delete"
                                                    disabled={disabled2}
                                                    style={{ marginLeft: "15px" }}
                                                    onClick={() => deleteItemList(2, item.id)}
                                                ></Button>
                                            }
                                            style={{
                                                width: "80%",
                                                minWidth: "300px",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Row justify="space-around" align="middle">
                                                <Col span={24}>
                                                    <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                        <Col span={5}>
                                                            <Title level={4}>Tipo:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.type}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>Rango:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.range}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>Densidad máxima:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.max_density}</Title>
                                                        </Col>
                                                        <Col span={5}>
                                                            <Title level={4}>Altura máxima:</Title>
                                                            <Title style={{ fontSize: 15 }}>{item.max_height}</Title>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    )
                                }))
                            }
                        </Row>
                        {
                            list2 && (
                                <div className="site-card-border-less-wrapper" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "10px 0" }}>
                                    <Card
                                        title="Item de Edificabilidad"
                                        bordered={false}
                                        style={{
                                            width: 600,
                                        }}
                                    >
                                        <Row justify="space-around" align="middle">
                                            <Col span={24}>
                                                <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={11}>
                                                        <Title level={4}>Tipo de Edificabilidad</Title>
                                                        <Select
                                                            defaultValue="Seleccione una opción"
                                                            allowClear
                                                            style={{ width: "100%" }}
                                                            name='type'
                                                            onSelect={(e) => setArrBuildability({ ...arrBuildability, type: e })}
                                                        >
                                                            <Option value="basic">Básica</Option>
                                                            <Option value="max">Maxima</Option>
                                                        </Select>
                                                    </Col>
                                                    <Col span={11}>
                                                        <Title level={4}>Rango</Title>
                                                        <Input
                                                            placeholder="Escribe el rango"
                                                            name='range'
                                                            value={range}
                                                            onChange={(e) => handleInputChangeList(e.target, 2)}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row justify="space-around" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={11}>
                                                        <Title level={4}>Densidad máxima</Title>
                                                        <InputNumber
                                                            placeholder="Densidad máxima"
                                                            min={0.000000}
                                                            name='max_density'
                                                            value={max_density}
                                                            onChange={(e) => setArrBuildability({ ...arrBuildability, max_density: e })}
                                                        />
                                                    </Col>
                                                    <Col span={11}>
                                                        <Title level={4}>Altura máxima</Title>
                                                        <InputNumber
                                                            addonAfter="Altura máxima"
                                                            placeholder="Altura máxima"
                                                            min={0.000000}
                                                            name='max_height'
                                                            value={max_height}
                                                            onChange={(e) => setArrBuildability({ ...arrBuildability, max_height: e })}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row justify="space-evenly" align="middle" style={{ marginBottom: "5px" }}>
                                                    <Col span={12}>
                                                        <Button
                                                            type="primary"
                                                            primary
                                                            style={{ marginLeft: "14px" }}
                                                            onClick={() => saveList(2)}
                                                        >
                                                            GUARDAR
                                                        </Button>
                                                        <Button
                                                            type="dashed"
                                                            danger
                                                            style={{ marginLeft: "15px" }}
                                                            onClick={() => changeShowList(2)}
                                                        >
                                                            CANCELAR
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                            )
                        }
                        <Row>
                            <Col span={24}>
                                <Button
                                    type="dashed"
                                    primary
                                    icon="plus-circle"
                                    style={{ margin: "10px" }}
                                    onClick={() => changeShowList(2)}
                                >
                                    AGREGAR
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <h3>Uso del suelo</h3>
                            </Col>
                        </Row>
                        <Row justify="center" align="middle" style={{ margin: "10px 10px" }}>
                            <Col span={24} style={{ display: "flex", alignItems: "center" }}>
                                <S3File
                                    idComponent="btn-send-buy-camacol-s3"
                                    path="lands"
                                    finalPath="land_use_path"
                                    handleUploadFinish={(e) => setForm({ ...form, land_use_path: e })}
                                    handleDeleteFinish={(e) => setForm({ ...form, land_use_path: e })}
                                    id={props.id}
                                    land_use_path={land_use_path}
                                    nameBtn="Subir Archivo"
                                />
                            </Col>
                        </Row>
                        <Row justify="center" align="middle" style={{ margin: "10px 0" }}>
                            <Col span={24} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Button
                                    type="primary"
                                    primary
                                    onClick={saveChanges}
                                >
                                    ACTUALIZAR
                                </Button>
                            </Col>
                        </Row>
                    </Content>
                </Layout >
            </Form >

        </>
    )
}
