import React, { Component } from 'react';
import {CompanyTitle} from './';
import { Card, CardContent, Typography } from '@material-ui/core';
import {Show, SimpleShowLayout} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RemoveRedEye from '@material-ui/icons/RemoveRedEye';
import { Grid } from '@material-ui/core';
import { companiesService, companiesContact } from '../../connections/feathers';
import { Link } from "react-router-dom";

export default class PostShow extends Component {

    constructor(props){
        super(props)
        this.state={
            users:[],
            company:{
                group:'',
                affiliate_status:{
                    name:null
                }
            },
            company_contacts:[]
        };
    }

    componentDidMount(){
        this.setState({load:true})
    }

    componentDidUpdate = async () => {
        if(this.state.load){

            await Promise.all([
                companiesService.find({query:{id: this.props.match.params.id, $limit:100000}}).then((response) => {
                    this.setState({company:response.data[0], users: response.data[0].users, affiliate_status: response.data[0].affiliate_status})
                }),
                companiesContact.find({query:{company_id: this.props.match.params.id, $limit:100000}}).then((response) => {
                    console.log(response.data)
                    this.setState({company_contacts:response.data})
                })
            ]);

            this.setState({load:false})
        }
    }

    render(){
        const { users, company, company_contacts } = this.state;
        console.log(company_contacts)
        return(
            <Show {...this.props} title={<CompanyTitle/>}>
                <SimpleShowLayout>
                    <Grid container spacing={8}>
                        <Grid item xs={8}>
                            <h4>Listado de usuarios</h4>
                            <Paper >
                                <Table >
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Nombres</TableCell>
                                        <TableCell align="right">Correo</TableCell>
                                        <TableCell align="right">Numero de telefono</TableCell>
                                        <TableCell align="right">Editar</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {users.map(user => (
                                        <TableRow key={user.id}>
                                        <TableCell component="th" scope="row">
                                            {`${user.first_name} ${user.last_name}`}
                                        </TableCell>
                                        <TableCell align="right">{user.email}</TableCell>
                                        <TableCell align="right">{user.phone_number}</TableCell>
                                        <TableCell align="right">
                                            <Link to={`/users/${user.id}`}> <RemoveRedEye /></Link>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                                <h4>Datos de la empresa</h4>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={8}>
                                        <Grid item xs={8}>
                                            <Typography color="textSecondary" >
                                                Nit
                                            </Typography>
                                            <Typography  >
                                                {company.nit}
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Comisión
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                {company.management_fee}
                                            </Typography>
                                            <Typography color="textSecondary" >
                                                    Afiliación
                                            </Typography>
                                            <Typography >
                                            {
                                                !company.affiliate_status ? 'Sin afiliación' : company.affiliate_status.name
                                            }
                                            </Typography>
                                            <Typography color="textSecondary" >
                                                Grupo
                                            </Typography>
                                            <Typography gutterBottom>
                                                { !company.group.name ? 'Sin grupo' : company.group.name }
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography color="textSecondary" gutterBottom>
                                                Dirección
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                {company.address}
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Telefono fijo
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                { company.fix_phone_number }
                                            </Typography>
                                            <Typography color="textSecondary" gutterBottom>
                                                Celular
                                            </Typography>
                                            <Typography color="textPrimary" gutterBottom>
                                                { company.mobile_phone_number }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography color="textSecondary" gutterBottom>
                                            Sitio Web
                                        </Typography>
                                        <Typography color="textPrimary" gutterBottom>
                                            { company.website }
                                        </Typography>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <h4>Listado de contactos</h4>
                            <Paper>
                                <Table>
                                    <TableHead>
                                    <TableRow>
                                        <TableCell>Nombres</TableCell>
                                        <TableCell align="right">Correo</TableCell>
                                        <TableCell align="right">Numero de telefono</TableCell>
                                        <TableCell align="right">Numero de celular</TableCell>
                                        <TableCell align="right">Posición</TableCell>
                                        <TableCell align="right">Departamento</TableCell>
                                        <TableCell align="right">Editar</TableCell>
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {company_contacts.map(company_contact => (
                                        <TableRow key={company_contact.id}>
                                            <TableCell component="th" scope="row">
                                                {`${company_contact.first_name} ${company_contact.last_name}`}
                                            </TableCell>
                                            <TableCell align="right">{company_contact.email}</TableCell>
                                            <TableCell align="right">{company_contact.phone_number}</TableCell>
                                            <TableCell align="right">{company_contact.mobile_phone_number}</TableCell>
                                            <TableCell align="right"><Link to={`/contact-position-options/${company_contact.contact_position.id}`}>{company_contact.contact_position.name}</Link></TableCell>
                                            <TableCell align="right"><Link to={`/contact-deparment-options/${company_contact.deparment.id}`}>{company_contact.deparment.name}</Link></TableCell>
                                            <TableCell align="right">
                                                <Link to={`/users/${company_contact.id}`}> <RemoveRedEye /></Link>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </SimpleShowLayout>
            </Show>
        )
    }
};