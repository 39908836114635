import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';
import { GroupsOptionsTitle } from './';

export default class GroupsOptionsEdit extends Component{
    render(){
        return(
            <Edit  title={<GroupsOptionsTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                </SimpleForm>
            </Edit >
        )
    }
}