import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';
import { SegmentationsOptionsEditTitle } from './';

export default class SegmentationsOptionsEdit extends Component{
    render(){
        return(
            <Edit  title={<SegmentationsOptionsEditTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                </SimpleForm>
            </Edit >
        )
    }
}