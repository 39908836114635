import React from 'react';
import { Tabs } from 'antd';

import { SecondEdit } from './secondEdit';
import LandsEdit from './edit';

const { TabPane } = Tabs;

const TabsEdits = (props) => (
    <Tabs defaultActiveKey="1">
        <TabPane tab="Lote" key="1">
            <LandsEdit {...props} />
        </TabPane>
        <TabPane tab="Alineamiento / Edificabilidad" key="2">
            <SecondEdit {...props} />
        </TabPane>
    </Tabs>
);

export default TabsEdits;