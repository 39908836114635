import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton
} from 'react-admin';

export default class SystemVariablesList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} >
                <Datagrid>
                    <TextField source="id" label="id" />
                    <TextField source="description" label="Descripción" />
                    <TextField source="key" label="Llave" />
                    <TextField source="value" label="Valor" />
                    <TextField source="status" label="Estado" />

                    <EditButton label="Editar" />
                </Datagrid>
            </List>
        )
    }
};