import React, { Component } from 'react';
import { List, Datagrid, TextField,
        EditButton } from 'react-admin';

export default class AffiliateStatusOptionsList extends Component{
    render(){
        return(
        <List 
        exporter={false}
        {...this.props} title="Listado de las opciones de departamentos de contactos">
            <Datagrid>
                <TextField source="id" label="id" />
                <TextField source="name" label="Nombre" />
                <EditButton label="Editar"/>
            </Datagrid>
        </List>
        )
    }
};