import React from 'react';
import {
    Create, SimpleForm, TextInput,
    SelectInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';

const dataState = [
    { value: 'active', description: 'Activo' },
    { value: 'inactive', description: 'Inactivo' }
];

const ConstructionProceduresCreate = (props) => {
    return (
        <Create title='Crear Procedimiento de Construcción' {...props}>
            <SimpleForm>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="city_name" label="Nombre de la ciudad" fullWidth={true} />
                        <TextInput source="observation" label="observación" fullWidth={true} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="contact_email" label="Correo" fullWidth={true} />
                        <SelectInput label="Estado" choices={dataState} optionText="description" source="status" optionValue="value" fullWidth={true} /><br></br>
                        <TextInput source="position" label="Posición" fullWidth={true} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default ConstructionProceduresCreate;