import React from 'react';
import CompaniesContactCreate from './create';
import CompaniesContactList from './lists';
import CompaniesContactEdit from './edit';
import CompaniesContactShow from './show';
import CompaniesContactIcon from '@material-ui/icons/RecentActors';

const CompaniesContactTitle = ({source, record = {}}) => {
    return(
        <span>Editando {record ? `"${record.first_name} ${record.last_name}"` : ''}</span>
    )
};

const CompaniesContactShowTitle = ({source, record = {}}) => {
    return(
        <span>Mostrando {record ? `"${record.first_name} ${record.last_name}"` : ''}</span>
    )
};

export {CompaniesContactCreate, CompaniesContactList, CompaniesContactTitle, CompaniesContactEdit, CompaniesContactShow, CompaniesContactShowTitle, CompaniesContactIcon};