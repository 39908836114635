import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';
import { ContactPositionOptionsTitle } from './';

export default class ContactPositionOptionsEdit extends Component{
    render(){
        return(
            <Edit  title={<ContactPositionOptionsTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                </SimpleForm>
            </Edit >
        )
    }
}