import React from 'react';
import { SimpleForm, TextInput, Create,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';

const MeansCreate = (props) => {

    return (
        <Create title={"Crear Recursos"} {...props}>
            <SimpleForm>
                <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                    <TextInput source="description" label="Descripción" fullWidth  />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextInput source="position" label="Posición" fullWidth />
                </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default MeansCreate;