import UsersList from './lists';
import UsersEdit from './edit';
import UserIcon from '@material-ui/icons/Person';
import UsersCreate from './create';
import React from 'react';

const UserTitle = ({ record }) => {
    return <span>Editando al Usuario {record ? `${record.first_name} ${record.last_name}` : ''}</span>;
};

export {UsersList, UsersEdit, UserIcon, UserTitle, UsersCreate};