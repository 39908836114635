import React, { Component } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
  ReferenceInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";

const choices = [
  { id: "true", description: "Activo" },
  { id: "false", description: "Inactivo" },
];

export default class UsersCreate extends Component {
  render() {
    return (
      <Create title="Crear usuario" {...this.props}>
        <SimpleForm>
          <Grid container spacing={8}>
            <Grid item xs={2} spacing={1}>
              <TextInput source="first_name" label="Nombres" />
              <TextInput source="email" label="Correo" type="email" />
            </Grid>
            <Grid item xs={2} spacing={1}>
              <TextInput source="last_name" label="Apellidos" />
              <TextInput source="password" label="Contraseña" type="password" />
            </Grid>
            <Grid item xs={4}>
              <div>
                <ReferenceInput
                  sort={{
                    field: "name",
                    order: "ASC",
                  }}
                  perPage={50000}
                  label="Empresas"
                  source="company_id"
                  reference="companies"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </div>
              <div>
                <ReferenceInput
                  label="Rol de empresa"
                  source="company_role_id"
                  reference="company-role-options"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </div>
              <div>
                <TextInput source="phone_number" label="Telefono" />
              </div>
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                source="active"
                choices={choices}
                optionText="description"
                optionValue="id"
                label="Estado"
              />
              <br></br>
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}
