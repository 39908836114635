import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton, ReferenceField,
    EmailField, Filter, TextInput, SelectInput, ReferenceInput
} from 'react-admin';

const CompaniesContactFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nombres" source="first_name" alwaysOn />
        <ReferenceInput label="Empresa" source="company_id" reference="companies">
            <SelectInput source="name" />
        </ReferenceInput>
    </Filter>
);


const ShowAllUserFied = ({ record }) => {
    return record ? `${record.show_affiliates_directory === 'true' ? 'Si' : 'No'}` : '';
}

const FullNameField = ({ record }) => {
    return record ? `${record.first_name} ${record.last_name}` : null
}

FullNameField.defaultProps = { label: 'Nombre' };

export default class CompaniesContactList extends Component {
    render() {
        return (
            <List 
            exporter={false}
            {...this.props} filters={<CompaniesContactFilter />} title="Contactos de empresas">
                <Datagrid>
                    <TextField source="id" label="id" />
                    <FullNameField />
                    <EmailField source="email" label="Correo" />
                    <TextField source="phone_number" label="Telefono" />
                    <TextField source="mobile_phone_number" label="Numero de celular" />
                    <ReferenceField label="Empresa" source="company_id" reference="companies">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Posición" source="contact_position_id" reference="contact-position-options">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Posición" source="contact_deparment_id" reference="contact-deparment-options">
                        <TextField source="name" />
                    </ReferenceField>
                    <ShowAllUserFied source="show_affiliates_directory" label="Mostrar a todos los usuarios" />
                    <EditButton label="Editar" />
                </Datagrid>
            </List>
        )
    }
};