import React from 'react';
import {
    List, Datagrid, TextField, Filter, ReferenceField,
    EditButton, TextInput, SelectInput, DeleteButton, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';

const FontField = ({ record }) => {
    let classField = record.fund === 'true' ? "colorTrue" : "colorFalse"
    record.fund === 'true' ? record.fund = 'Si' : record.fund = 'No';
    return (
        <TextField source="fund" record={record} className={classField} />
    )
}

const ImageField = ({ record }) => (
    record.image_path ? <img src={`https://micamacol.s3.amazonaws.com/${record.image_path}`} alt="Img" className="imgList" /> : null
)

FontField.defaultProps = {
    label: 'Fondo',
    addLabel: true
};

const dataTypes = [
    { value: 'Servicios', description: 'Servicios' },
    { value: 'Insumos', description: 'Insumos' }
];

const dataFund = [
    { value: 'true', description: 'Si' },
    { value: 'false', description: 'No' }
];

const BuyCamacolFilter = (props) => (
    <Filter {...props} >
        <TextInput label="Nombre" source="name" />
        <SelectInput label="Tipo" choices={dataTypes} optionText="description" source="type" optionValue="value" />
        <SelectInput label="Fondo" choices={dataFund} optionText="description" source="fund" optionValue="value" />
    </Filter>
);

const BuyCamacolList = (props) => {

    return (
        <List title="Camacol compra camacol"
        exporter={false}
        {...props} filters={<BuyCamacolFilter />} >
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField label="Nombre" source="name" />
                <TextField label="Tipo" source="type" />
                <ReferenceField label="Empresa" source="company_id" reference="companies">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField label="Categoria" source="buy_camacol_category_id" reference="buy-camacol-categories">
                    <TextField source="name" />
                </ReferenceField>
                <ImageField source="image_path" className="imgList" label="Imagen" />
                <ReferenceArrayField label="Ciudades" reference="locations-cities" source="locations_cities_ids">
                    <SingleFieldList>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {/* <FontField /> */}
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default BuyCamacolList;

