import { Button, Icon } from 'antd';
import React, { Component } from 'react';
// import { notification , message, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import S3Uploader from 'react-s3-uploader';
const { REACT_APP_API_ENDPOINT } = process.env;

class App extends Component {

  state = {
    progress: null
  };

  componentDidMount() {
  }

  onUploadStart = (file, next) => {
    console.log({ file, next });
    this.setState({ name_file: file.name })
    next(file);
  }

  onSignedUrl = (...props) => {
  }

  onUploadProgress = (progress, ...props) => {
    this.setState({ progress });
  }

  onUploadError = (error) => {
  }

  onUploadFinish = (urls) => {
    this.props.handleUploadFinish(urls.fileKey, this.props.id)
  }

  deleteFile = () => {
    this.setState({ name_file: null })
    this.props.handleDeleteFinish(null)
  }

  render() {
    const { file = {}, match, nameBtn } = this.props;

    if (file.name) return (
      <div className="s3Button mr-2">
        {file.name}
      </div>
    );

    return (
      <div className="mr-2">
        <label
          htmlFor={this.props.idComponent}
          className="flat-button-file"
          variant="outlined"
          color="primary"
        >{nameBtn ? nameBtn : "Subir imagen"}</label>
        {
          this.state.name_file &&
          <div style={{ marginTop: "10px" }}>
            {this.state.name_file}
            {
              nameBtn &&
              <Button
                type="dashed"
                danger
                shape="circle"
                icon={<Icon type="delete" />}
                style={{ marginLeft: "15px" }}
                onClick={this.deleteFile}
              ></Button>
            }
          </div>
        }
        {
          this.state.name_file === undefined && this.props.land_use_path &&
          <div style={{ marginTop: "10px" }}>
            {this.props.land_use_path}
            {
              nameBtn &&
              <Button
                type="dashed"
                danger
                shape="circle"
                icon={<Icon type="delete" />}
                style={{ marginLeft: "15px" }}
                onClick={this.deleteFile}
              ></Button>
            }
          </div>
        }
        <label className="s3Button">
          <S3Uploader
            id={this.props.idComponent}
            signingUrl="/s3Client/sign"
            signingUrlMethod="GET"
            accept="*/*"
            s3path={`${this.props.path}/${match.params.id}/${this.props.finalPath}/`}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true}      // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
            contentDisposition={this.props.contentDisposition || 'auto'}
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
            server={`https://${REACT_APP_API_ENDPOINT}`}
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: 'hidden' }}
          />
        </label>
      </div>
    );
  }
}

export default withRouter(App);
