import React from 'react';
import ContactPositionOptionsCreate from './create';
import ContactPositionOptionsList from './list';
import ContactPositionOptionsEdit from './edit';

import ContactPositionOptionsIcon from '@material-ui/icons/Contacts';

const ContactPositionOptionsTitle = ({ record }) => {
    return <span> Posición de contacto {record ? `"${record.name}"` : ''}</span>;
};

export {ContactPositionOptionsCreate, ContactPositionOptionsIcon, ContactPositionOptionsTitle, ContactPositionOptionsList, ContactPositionOptionsEdit };