import React from 'react';
import {
    List, Datagrid, TextField,
    EditButton, DeleteButton,

} from 'react-admin';


const MenuList = (props) => {

    return (
        <List title="Procedimientos de Construcción" {...props}>
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField source="icon_path" label="Icono" />
                <TextField label="Nombre" source="name" />
                <TextField label="Tipo" source="type" />
                <TextField label="Path" source="path" />
                <TextField label="Estado" source="status" />
                <TextField label="Posición" source="position" />
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default MenuList;

