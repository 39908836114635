import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton, Filter, TextInput,
    NumberField
} from 'react-admin';


const LandFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Busqueda" source="name" alwaysOn />
    </Filter>
);


/* 
createdAt: "2020-06-16T13:47:43.000Z"
department: "ADMNISTRATIVA Y FINANCIERA"
email: "morjuela@camacolatlantico.org"
id: 1
job_position: "Directora Administrativa y financiera"
name: "Monica Orjuela Pereira"
phone: "3851050 Ext. 104"
position: null
updatedAt: "2020-06-16T13:47:43.000Z"

*/
export default class LandsList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} filters={<LandFilter />}>
                <Datagrid>
                    <TextField label="Id" source="id" />
                    <TextField label="Nombre" source="name" />
                    <TextField source="job_position" label="Cargo" />
                    <TextField source="department" label="Departamento" />
                    <TextField source="email" label="Correo Electrónico" />
                    <TextField source="phone" label="Teléfono" />
                    <NumberField source="position" label="Posición" />
                    <EditButton />
                </Datagrid>
            </List>
        )
    }
};