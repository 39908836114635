import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton, ReferenceField, ShowButton, Filter, TextInput,
} from 'react-admin';

const CompaniesEconomicActivityFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nombres" source="first_name" alwaysOn />
    </Filter>
);


export default class CompaniesEconomicActivityList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} filters={<CompaniesEconomicActivityFilter />}>
                <Datagrid>
                    <TextField source="id" label="id" />
                    <ReferenceField label="Empresa" source="company_id" reference="companies">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="name" />
                    <EditButton label="Editar" />
                    <ShowButton label="Ver" />
                </Datagrid>
            </List>
        )
    }
};