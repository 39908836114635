import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton, ReferenceField, Filter, TextInput
} from 'react-admin';


const LandFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Busqueda" source="name" alwaysOn />
    </Filter>
);

export default class LandsList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} filters={<LandFilter />}>
                <Datagrid>
                    <TextField label="Id" source="id" />
                    <TextField label="Dirección" source="name" />
                    <TextField source="real_estate_registration" label="Matricula Inmobiliaria" />
                    <TextField source="lat" label="Latitud" />
                    <TextField source="long" label="Longitud" />
                    <ReferenceField label="Empresa" source="affiliate_id" reference="companies">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="area_m2" label="Area m2" />
                    <ReferenceField label="Ciudad" source="city_id" reference="locations-cities">
                        <TextField source="name" />
                    </ReferenceField>
                    <TextField source="neighborhood" label="Longitud" />
                    <TextField source="price" label="Precio" />
                    <EditButton />
                </Datagrid>
            </List>
        )
    }
};