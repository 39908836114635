import React, { Component } from 'react';
import { Create, SimpleForm, TextInput, NumberInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { validateLandsFields } from './'

export default class LandsCreate extends Component {

    render() {

        return (
            <Create title="Crear lote" {...this.props}>
                <SimpleForm validate={validateLandsFields}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <TextInput label="Nombre" source="name" />
                            <TextInput source="job_position" label="Cargo" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput source="department" label="Departamento" />
                            <TextInput source="email" label="Correo Electrónico" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput source="phone" label="Teléfono" />
                            <NumberInput source="position" label="Posición" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Create>
        )
    }
};