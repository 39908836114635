import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';
import { ContactDeparmentOptionsTitle } from './';

export default class ContactDepartmentOptionsEdit extends Component{
    render(){
        return(
            <Edit  title={<ContactDeparmentOptionsTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                </SimpleForm>
            </Edit >
        )
    }
}