import GroupsOptionsCreate from './create';
import GroupsOptionsEdit from './edit';
import GroupsOptionsList from './list';
import GroupsOptionsIcon from '@material-ui/icons/Group';

const GroupsOptionsTitle = ({record}) =>{
    return (
        record ? `"${record.name}"` : ''
    )
}

export { GroupsOptionsCreate, GroupsOptionsEdit, GroupsOptionsList, GroupsOptionsTitle, GroupsOptionsIcon };