import React, { Component } from "react";
import { Layout, Row, Col } from "antd";
import { FileUploader } from "../com/";
import { URL_BASE_API } from "../../constants";

const { Content } = Layout;
export default class BuildingActivity extends Component {
  render() {
    return (
      <Content>
        <Row type="flex" justify="center" align="middle">
          <Col>
            <FileUploader>IMPORTAR EXCEL lIVO</FileUploader>
            <FileUploader action={`${URL_BASE_API}/import-coordenada-urbana`}>
              IMPORTAR EXCEL BASE
            </FileUploader>
          </Col>
        </Row>
      </Content>
    );
  }
}
