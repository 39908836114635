import React from 'react';
import {
    List, Datagrid, TextField, Filter,
    EditButton, TextInput, DeleteButton
} from 'react-admin';

const Filters = (props) => (
    <Filter {...props} >
        <TextInput label="Nombre" source="name" alwaysOn />
    </Filter>
);

const BuyCamacolList = (props) => {

    return (
        <List title="Ciudades"
            exporter={false}
            {...props} filters={<Filters />} >
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField label="Nombre" source="name" />
                {/* <FontField /> */}
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default BuyCamacolList;

