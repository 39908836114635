import React, { useEffect, useState } from 'react';
import {
    Edit, SimpleForm, TextInput, Show, SimpleShowLayout,
    SelectInput, Labeled,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { S3File } from '../s3';
import { upliftingActivityResources } from '../../connections/feathers';

const dataType = [
    { value: 'url', description: 'URL' },
    { value: 'file', description: 'File' }
];

const dataState = [
    { value: 'active', description: 'Activo' },
    { value: 'inactive', description: 'Inactivo' }
];

const MeansEdit = (props) => {
    const [typeValue, setTypeValue] = useState([])
    const [typeValueValidated, setTypeValueValidated] = useState("")
    const [filePath, setFilePath] = useState("")
    const handleChangeType =(value)=>{
        const auxObj = Object.values(value)
        const auxArr= []
        for(let i=0; i<auxObj.length; i++){
            if(typeof auxObj[i] === "string"){
                auxArr.push(auxObj[i])
            }
        }
        setTypeValueValidated(auxArr.join(""),)
    }

    const handleUploadFinishFile = async (url, id) => {
        await upliftingActivityResources.patch(id, { path: url }).then(it => setFilePath(it.path))
    }

    useEffect(() => {
        handleChangeType(typeValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeValue])

    useEffect(() => {
        upliftingActivityResources.find({ query: { id: props.id } }).then(it => setFilePath(it.data[0].path))
        upliftingActivityResources.find({ query: { id: props.id } }).then(it => setTypeValueValidated(it.data[0].type))
    }, [props.id]);

    return (
        <Edit title={"Editar Recursos"} {...props}>
            <SimpleForm>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="description" label="Descripción" fullWidth />
                        <SelectInput onChange={(e)=>setTypeValue(e)} label="Tipo" choices={dataType} optionText="description" source="type" optionValue="value" fullWidth={true} /><br></br>
                        {
                            typeValueValidated === "url" ?(
                                <TextInput source="path" label="Path" fullWidth={true} />
                            ):
                            typeValueValidated === "file" ?(
                                <>
                                    <Labeled label="Sube aquí el archivo Que quieres que se descargue">
                                        <S3File nameBtn="Subir Archivo" idComponent="btn-send-buy-camacol-s3_2" path="path" finalPath="files" handleUploadFinish={handleUploadFinishFile} id={props.id} />
                                    </Labeled>
                                    <Show {...props} actions={false} className="showEdit">
                                        <SimpleShowLayout className="showEditSimpleLayout">
                                            <Grid item xs={12}>
                                                <a href={`https://micamacol.s3.amazonaws.com/${filePath}`}>{filePath}</a>
                                            </Grid>
                                        </SimpleShowLayout>
                                    </Show>

                                </>
                            ):null

                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="position" label="Posición"  fullWidth/>
                        <SelectInput label="Estado" choices={dataState} optionText="description" source="status" optionValue="value" fullWidth={true} /><br></br>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

export default MeansEdit;