import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubMenu from './subMenu';
import { UserIcon } from '../users';
import { CompanyIcon } from '../companies';
import { CompaniesContactIcon } from '../companies-contacts';
import { ContactDeparmentOptionsIcon } from '../contact-department-options';
import { AffiliateStatusOptionsIcon } from '../affiliate-status-options';
import { SegmentationsOptionsIcon } from '../segmentations-options';
import { ContactPositionOptionsIcon } from '../contact-position-options';
import { GroupsOptionsIcon } from '../group-options';
import { CompanyRoleOptionsIcon } from '../company-role-options';
import { BuyCamacolIcon } from '../buy-camacol';
import { LandsIcon } from '../lands'

//import Store from '@material-ui/icons/Store';
/* 
import ApartmentIcon from '@material-ui/icons/Apartment'; */
import ApartmentIcon from '@material-ui/icons/Business';
import ContactMailIcon from '@material-ui/icons/ContactMail';

import {
    MenuItemLink,
    Responsive,
} from 'react-admin';

export default class Menu extends Component {
    state = {
        menuCatalog: false,
        menuSales: false,
        menuCustomers: false,
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object,
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, logout } = this.props;
        return (
            <div>
                {/* <DashboardMenuItem onClick={onMenuClick} /> */}
                <SubMenu
                    handleToggle={() => this.handleToggle('menuCompany')}
                    isOpen={this.state.menuCompany}
                    sidebarIsOpen={false}
                    name="Empresas"
                    icon={<CompanyIcon />}
                >
                    <MenuItemLink className={"menuItems"}
                        to={`/companies`}
                        primaryText={'Empresas'}
                        onClick={onMenuClick}
                        leftIcon={<CompanyIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/companies-contacts`}
                        primaryText={'Contactos'}
                        onClick={onMenuClick}
                        leftIcon={<CompaniesContactIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/company-segmentation`}
                        primaryText={'Segmentaciones'}
                        onClick={onMenuClick}
                        leftIcon={<SegmentationsOptionsIcon />}
                    />
                    {/* <MenuItemLink className={"menuItems"}
                        to={`/company-economic-activity`}
                        primaryText={'Actividad Economica'}
                        onClick={onMenuClick}
                        leftIcon={<CompanyIcon />}
                    /> */}
                </SubMenu>
                <SubMenu
                    handleToggle={() => this.handleToggle('menuConfigurations')}
                    isOpen={this.state.menuConfigurations}
                    sidebarIsOpen={false}
                    name="Configuración"
                    icon={<CompanyIcon />}
                >
                    {/* <MenuItemLink className={"menuItems"}
                        to={`/economic-activity-options`}
                        primaryText={'Actividad Econ'}
                        onClick={onMenuClick}
                        leftIcon={<EconomicActivityOptionsIcon />}
                    /> */}


                    {/* <SubMenu
                        handleToggle={() => this.handleToggle('menuConfigurationsContact')}
                        isOpen={this.state.menuConfigurationsContact}
                        sidebarIsOpen={false}
                        name="Contactos"
                        icon={<CompanyIcon />}
                    > */}

                    <MenuItemLink className={"menuItems"}
                        to={`/contact-position-options`}
                        primaryText={'Posiciones'}
                        onClick={onMenuClick}
                        leftIcon={<ContactPositionOptionsIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/contact-deparment-options`}
                        primaryText={'Departamentos'}
                        onClick={onMenuClick}
                        leftIcon={<ContactDeparmentOptionsIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/affiliate-status-options`}
                        primaryText={'Afiliaciones'}
                        onClick={onMenuClick}
                        leftIcon={<AffiliateStatusOptionsIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/segmentation-options`}
                        primaryText={'Segmentaciones'}
                        onClick={onMenuClick}
                        leftIcon={<SegmentationsOptionsIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/group-options`}
                        primaryText={'Grupos'}
                        onClick={onMenuClick}
                        leftIcon={<GroupsOptionsIcon />}
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/company-role-options`}
                        primaryText={'Roles de empresas'}
                        onClick={onMenuClick}
                        leftIcon={<CompanyRoleOptionsIcon />}
                    />
                    <MenuItemLink
                        className={"menuItems"}
                        to={`/locations-cities`}
                        primaryText={'Ciudades'}
                        onClick={onMenuClick}
                    /* leftIcon={<UserIcon />} */
                    />
                    <MenuItemLink
                        className={"menuItems"}
                        to={`/locations-states`}
                        primaryText={'Estados/Departamentos'}
                        onClick={onMenuClick}
                    /* leftIcon={<UserIcon />} */
                    />
                    <MenuItemLink
                        className={"menuItems"}
                        to={`/configurations`}
                        primaryText={'variables del sistema'}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        className={"menuItems"}
                        to={`/menu`}
                        primaryText={'Menu'}
                        onClick={onMenuClick}
                        // leftIcon={<LandsIcon />}
                    />
                    {/* </SubMenu> */}
                </SubMenu>

                <SubMenu
                    handleToggle={() => this.handleToggle('buyCamacol')}
                    isOpen={this.state.buyCamacol}
                    sidebarIsOpen={false}
                    name="Compra Camacol"
                    icon={<BuyCamacolIcon />}
                >

                    <MenuItemLink
                        className={"menuItems"}
                        to={`/buy-camacol`}
                        primaryText={'Publicaciones'}
                        onClick={onMenuClick}
                    /* leftIcon={<BuyCamacolIcon />} */
                    />
                    <MenuItemLink className={"menuItems"}
                        to={`/buy-camacol-categories`}
                        primaryText={'Categorias'}
                        onClick={onMenuClick}
                    /* leftIcon={<EconomicActivityOptionsIcon />} */
                    />
                </SubMenu>

                <MenuItemLink
                    className={"menuItems"}
                    to={`/lands`}
                    primaryText={'Lotes'}
                    onClick={onMenuClick}
                    leftIcon={<LandsIcon />}
                />
                <SubMenu
                    handleToggle={() => this.handleToggle('buildingActivity')}
                    isOpen={this.state.buildingActivity}
                    sidebarIsOpen={false}
                    name="Actividad edificadora"
                    icon={<ApartmentIcon />}
                >
                    <MenuItemLink
                    className={"menuItems"}
                    to={`/upload-excel-livo`}
                    primaryText={'Actividad Edificadora'}
                    onClick={onMenuClick}
                    // leftIcon={<ApartmentIcon />}
                    />
                    <MenuItemLink
                    className={"menuItems"}
                    to={`/uplifting-activity-resources`}
                    primaryText={'recursos'}
                    onClick={onMenuClick}
                    />
                </SubMenu>
                <MenuItemLink
                    className={"menuItems"}
                    to={`/contact-info`}
                    primaryText={'Informacion de Contacto'}
                    onClick={onMenuClick}
                    leftIcon={<ContactMailIcon />}
                />

                <MenuItemLink
                    className={"menuItems"}
                    to={`/users`}
                    primaryText={'Usuarios'}
                    onClick={onMenuClick}
                    leftIcon={<UserIcon />}
                />
                <MenuItemLink
                    className={"menuItems"}
                    to={`/construction-procedures`}
                    primaryText={'Procedimientos de Construcción'}
                    onClick={onMenuClick}
                    // leftIcon={<LandsIcon />}
                />
                
                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}