import React from 'react'
import CompanyList from './lists';
import CompanyEdit from './edit';
import CompanyShow from './show';
import CompanyCreate from './create';
import CompanyIcon from '@material-ui/icons/Business';

const CompanyTitle = ({ record }) => {
    return <span>Datos de la empresa {record ? `"${record.name}"` : ''}</span>;
};

export {CompanyList, CompanyEdit, CompanyTitle, CompanyShow, CompanyIcon, CompanyCreate};