import React, { Component } from 'react';
import {
    Edit, SimpleForm, DisabledInput, TextInput,
    NumberInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { LandTitle } from './'
import { segmentationsService, companiesSegmentationsService, companiesService } from '../../connections/feathers';
import { validateLandsFields } from './'

export default class LandsEdit extends Component {

    state = {
        segmentationsOptions: [{ name: 'NameSegmentation', id: 0 }],
        segmentationsOptionsActives: [2],
        imagePath: null
    }

    async fetchData() {

        Promise.all([

            segmentationsService.find({ query: { $limit: 1000 } })
                .then(it => this.setState({ segmentationsOptions: it.data })),

            companiesSegmentationsService.find({ query: { company_id: this.props.id, $limit: 1000 } })
                .then(it => this.setState({ segmentationsOptionsActives: it.data.map(it => (it.segmentation_id)) })),

            companiesService.get(this.props.id)
                .then(it => this.setState({ imagePath: it.logo }))
                .catch(error => console.log(error, '+++++'))

        ])

    }

    componentDidMount() {
        this.fetchData()
    }

    handleUploadFinish = async (url, id) => {
        await companiesService.patch(id, { logo: url }).then(it => this.setState({ imagePath: it.logo }))
    }

    render() {
        return (
            <Edit title={<LandTitle />} {...this.props}>
                <SimpleForm validate={validateLandsFields}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6} fullWidth>
                            <DisabledInput label="Id" source="id" fullWidth />
                            <TextInput label="Nombre" source="name" fullWidth/>
                            <TextInput source="job_position" label="Cargo" />
                        </Grid>
                        <Grid item xs={12} md={6} fullWidth>
                            <TextInput source="department" label="Departamento" />
                            <TextInput source="email" label="Correo Electrónico" />
                        </Grid>
                        <Grid item xs={12} md={6} fullWidth>
                            <TextInput source="phone" label="Teléfono" />
                            <NumberInput source="position" label="Posición" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }

}
