import React, { Component } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  CheckboxGroupInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { segmentationsService } from "../../connections/feathers";

export default class BuyCamacolCreate extends Component {
  state = {
    segmentationsOptions: [{ name: "NameSegmentation", id: 0 }],
  };

  async fetchData() {
    segmentationsService
      .find({ query: { $limit: 1000 } })
      .then((it) => this.setState({ segmentationsOptions: it.data }));
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { segmentationsOptions } = this.state;
    const buildingActivity = [
      { value: "active", description: "Activo" },
      { value: "unactive", description: "Inactivo" },
    ];
    return (
      <Create title="Crear una empresa" {...this.props}>
        <SimpleForm>
          <Grid container spacing={16}>
            <Grid item xs={4}>
              <TextInput source="name" label="Razón social" fullWidth />
              <TextInput source="nit" label="Nit" fullWidth />
              <TextInput source="fix_phone_number" label="Telefono" fullWidth />
              <TextInput
                source="mobile_phone_number"
                label="Celular"
                fullWidth
              />
              <TextInput source="address" label="Direccíon" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="Estado de la afiliación"
                source="affiliate_status_id"
                reference="affiliate-status-options"
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
              <ReferenceInput
                label="Grupo"
                source="group_id"
                reference="group-options"
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
              <TextInput
                source="management_fee"
                label="Cuota de sostenimiento"
                fullWidth
              />
              <TextInput source="website" label="Sitio web" fullWidth />
              <TextInput source="email" label="Correo" type="email" fullWidth />
            </Grid>
            <Grid item xs={4} alignContent="flex-start">
              <ReferenceInput
                label="Tipo de Afiliado"
                source="type_affiliate_id"
                reference="type-affiliates"
              >
                <SelectInput optionText="name" fullWidth />
              </ReferenceInput>
              <TextInput
                source="economic_activity"
                label="Actividad economica"
                fullWidth
              />
              <SelectInput
                label="Actividad Edificadora"
                choices={buildingActivity}
                optionText="description"
                source="building_activity"
                optionValue="value"
                fullWidth
              />
              <CheckboxGroupInput
                source="companies_segmentations_ids"
                label="Segmentaciones"
                choices={segmentationsOptions}
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Create>
    );
  }
}
