import React, { Component } from 'react';
import { Create, SimpleForm, SelectInput, TextInput, ReferenceInput, LongTextInput } from 'react-admin';
import { Grid } from '@material-ui/core';
import { validateLandsFields } from './'

const styles = {
    flex: {
        width: '100%'
    }
}



export default class LandsCreate extends Component {

    render() {

        return (
            <Create title="Crear lote" {...this.props}>
                <SimpleForm validate={validateLandsFields}>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <TextInput source="name" label="Dirección" fullWidth />
                            <TextInput source="real_estate_registration" label="Matricula Inmobiliaria" fullWidth />
                            <TextInput source="lat" label="Latitud" fullWidth />
                            <TextInput source="long" label="Longitud" fullWidth />
                            <TextInput source="area_m2" label="Metros 2" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <ReferenceInput perPage={5000} sort={{ field: "name", order: "ASC" }} label="Afiliado" source="affiliate_id" reference="companies" style={styles.flex}  >
                                <SelectInput optionText="name" labelWidth={10} />
                            </ReferenceInput>
                            <ReferenceInput label="Ciudad" source="city_id" reference="locations-cities" style={styles.flex}>
                                <SelectInput optionText="name" labelWidth={5} />
                            </ReferenceInput>
                            <TextInput source="neighborhood" label="Barrio" fullWidth />
                            <TextInput source="price" label="Precio" fullWidth />
                            <LongTextInput source="description" label="Descripción" />
                        </Grid>
                    </Grid>

                    {/* <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <h3>Uso del suelo</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <FileField source="land_use_path" title="Uso del suelo" download />
                        </Grid>

                    </Grid> */}
                </SimpleForm>
            </Create>
        )
    }
};