import React, { Component } from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';


export default class CompaniesEconomicActivityCreate extends Component {
    render() {
        return (
            <Create {...this.props}>
                <SimpleForm>
                    <ReferenceInput label="Empresa" source="company_id" reference="companies">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        )
    }
}