import React from 'react';
import LandsList from './list';
import LandsEdit from './edit';
import TabsEdits from './tabsEdits';
import LandsCreate from './create';
import { CropLandscape } from '@material-ui/icons'

const LandTitle = ({ record }) => {
    return <span>Lote {record ? `"${record.real_estate_registration}"` : ''}</span>;
};

const validateLandsFields = (values) => {
    const errors = {};
    if (!values.real_estate_registration) errors.real_estate_registration = ['EL campo matricula Inmobiliaria es requerido'];
    if (!values.name) errors.name = ['El nombre es requerido.'];
    if (!values.lat) errors.lat = ['EL campo latitud Inmobiliaria es requerido.'];
    if (!values.long) errors.long = ['EL campo longitud Inmobiliaria es requerido.'];
    if (!values.area_m2) errors.area_m2 = ['EL campo metros 2 es requerido.'];
    if (!values.affiliate_id) errors.affiliate_id = ['Debes seleccionar el afiliado.'];
    if (!values.neighborhood) errors.neighborhood = ['El campo barrio es requerido.'];
    if (!values.price) errors.price = ['El campo precio es requerido.'];
    if (!values.city_id) errors.city_id = ['Debes seleccionar una ciudad.'];

    return errors
}

const LandsIcon = CropLandscape;

export { LandsList, LandsEdit, LandTitle, LandsCreate, validateLandsFields, LandsIcon, TabsEdits };