import CompaniesEconomicActivityCreate from './create';
import CompaniesEconomicActivityList from './list';
import CompaniesEconomicActivityEdit from './edit';

const CompaniesEconomicActivityTitle = ({record}) =>{
    return(
        record ? `"${record.name}"` : ''
    )
}

export { CompaniesEconomicActivityCreate, CompaniesEconomicActivityList, CompaniesEconomicActivityEdit, CompaniesEconomicActivityTitle }