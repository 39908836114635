import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput,
    SelectInput, Create
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ANTD_ICONS } from '../../constants';
import { Icon } from 'antd'

const dataType = [
    { value: 'local', description: 'Local' },
    { value: 'external', description: 'Externo' }
];



const dataPath = [
    { value: '/home', description: 'Home' },
    { value: '/lotes', description: 'Lotes' },
    { value: '/contacto', description: 'Contacto' },
    { value: '/compra', description: 'Compra' },
    { value: '/afiliados', description: 'Afiliados' },
    { value: '/perfiles', description: 'Perfiles' },
    { value: '/asesoria-comercial', description: 'Actividad Edificadora' },
    { value: '/gestionar-lotes', description: 'Gestionar Lotes' },
    { value: '/gestionar-ofertas', description: 'Gestionar Ofertas' },
    { value: '/tramites_construccion', description: 'Tramites para la construcción' },
];

const dataState = [
    { value: 'active', description: 'Activo' },
    { value: 'inactive', description: 'Inactivo' }
];

const MenuCreate = (props) => {
    const [typeValue, setTypeValue] = useState([])
    const [typeValueValidated, setTypeValueValidated] = useState("")

    const handleChangeType =(value)=>{
        const auxObj = Object.values(value)
        const auxArr= []
        for(let i=0; i<auxObj.length; i++){
            if(typeof auxObj[i] === "string"){
                auxArr.push(auxObj[i])
            }
        }
        setTypeValueValidated(auxArr.join(""),)
    }

    useEffect(() => {
        handleChangeType(typeValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [typeValue])

    return (
        <Create title={"Editar Menu"} {...props}>
            <SimpleForm>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <SelectInput
                            search
                            label="Icon"
                            choices={ANTD_ICONS.map(key => ({
                                key,
                                render: <><Icon type={key} />&nbsp;&nbsp;&nbsp;{key}</>
                            }))}
                            optionText="render"
                            optionValue="key"
                            source="icon_path"
                            fullWidth
                        />
                        <SelectInput onChange={(e)=>setTypeValue(e)} label="Tipo" choices={dataType} optionText="description" source="type" optionValue="value" fullWidth={true} /><br></br>
                        {
                            typeValueValidated === "local" ?(
                                <><SelectInput label="Path" choices={dataPath} optionText="description" source="path" optionValue="value" fullWidth={true} /><br></br></>
                            ):
                            typeValueValidated === "external" ?(
                                <TextInput source="path" label="Path" fullWidth={true} />
                            ):null

                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput source="name" label="Nombre" fullWidth={true} />
                        <SelectInput label="Estado" choices={dataState} optionText="description" source="status" optionValue="value" fullWidth={true} /><br></br>
                        <TextInput source="position" label="Posición" fullWidth={true} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default MenuCreate;