import Store from '@material-ui/icons/Store';
import BuyCamacolList from './list';
import BuyCamacolCreate from './create';
import BuyCamacolEdit from './edit';

const BuyCamacolIcon = Store;

const validateBuyCamacolFields = (values) => {
    const errors = {};
    if (!values.name) errors.name = ['El nombre es requerido.'];
    if (!values.type) errors.type = ['Debes seleccionar el tipo.'];
    if (!values.company_id) errors.company_id = ['EL campo longitud Inmobiliaria es requerido.'];
    if (!values.buy_camacol_category_id) errors.buy_camacol_category_id = ['Debes seleccionar la categoria.'];
    if (!values.contact_full_name) errors.contact_full_name = ['El nombre del contacto es requerido.'];
    if (!values.contact_email) errors.contact_email = ['El correo del contacto es requerido.'];
    if (!values.contact_phone) errors.contact_phone = ['El telefono del contacto es requerido.'];

    return errors
}

export { BuyCamacolList, BuyCamacolIcon, BuyCamacolCreate, BuyCamacolEdit, validateBuyCamacolFields }