import React, { Component } from 'react';
import {
    List, Datagrid, TextField,
    EditButton, ReferenceField,
    Filter,
    ReferenceInput,
    SelectInput
} from 'react-admin';

const Filters = (props) => {
    return (
        <Filter {...props}>
            <ReferenceInput perPage={1000} alwaysOn label="Empresa" source="company_id" reference="companies">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput perPage={1000} alwaysOn label="Segmentación" source="segmentation_id" reference="segmentation-options">
                <SelectInput optionText="name" />
            </ReferenceInput>
        </Filter>
    )
};
export default class CompaniesSegmentationsList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} filters={<Filters {...this.props} />} title="Listado de las opciones de departamentos de contactos">
                <Datagrid>
                    <TextField source="id" label="id" />
                    <ReferenceField label="Empresa" source="company_id" reference="companies">
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField label="Segmentación" source="segmentation_id" reference="segmentation-options">
                        <TextField source="name" />
                    </ReferenceField>
                    <EditButton label="Editar" />
                </Datagrid>
            </List>
        )
    }
};