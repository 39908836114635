import React, { Component } from 'react';
import { Create, SimpleForm, TextInput} from 'react-admin';


export default class ContactPositionOptionsCreate extends Component{
    render(){
        return(
            <Create {...this.props} title="Crear una posición de contactos" >
                <SimpleForm>
                    <TextInput source="name" label="Nombre"/>
                </SimpleForm>
            </Create>
        )
    }
}