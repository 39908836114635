import React, { useState, useEffect } from 'react';
import {
    List, Datagrid, TextField,
    ReferenceInput,
    SelectInput,
    EditButton, ReferenceField, ShowButton, Filter, TextInput
} from 'react-admin';
import { Link } from "react-router-dom";
import { getService } from '../../connections/feathers'
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    background: #fff;
    margin: 10px 0px;
    box-shadow: 3px 3px 3px #cccccc75;
    border-radius: 8px;
`
const TagStatus = styled.div`
    margin: 0px 4px;
    padding: 8px;
    border-radius: 8px;
    /* border: 1px solid #ccc; */
    /* box-shadow: 2px 2px 3px #e4e4e4; */
    font-size: 16px;

    color:#fff;
    transition: opacity .1s ease,color .1s ease,font-size .5s ease,width .5s ease;
    text-align: center;
    text-transform: uppercase;

    & a{
        text-decoration: none;
        color: #fff;
    }
    & .label{
        font-weight:bold;
    }
    & .value{
        font-size:18px;
    }
    &.yellow{
        color:#1f2d3d;
        background:#FFE1A8;
    }
    &.grey{
        color:#424242;
        background:#8499B1;
    }
    &.yellow a {
        color:#1f2d3d;
    }
    &.green{
        background:#519E8A;
    }
    &.red{
        background:#D9594C;
    }
`
const MAX = 50;
const CustomStatus = ({ choices }) => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)

    useEffect(() => {
        getData(page);
        console.log("Get Data ", page)
        return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //choices
    const getGrops = () => {
        let groups = {}
        let view = []
        data.reduce((prev, current) => {
            groups[current.affiliate_status.name] = groups[current.affiliate_status.name] || []
            groups[current.affiliate_status.name].push(current)
            return current;
        }, groups)

        console.log(groups)
        const status = [
            {
                id: "A1",
                name: "Afiliado",
                cls: "green"
            },
            {
                id: "A2",
                name: "Desafiliado",
                title: "Desafiliados",
                cls: "yellow"
            },
            {
                id: "A3",
                name: "Afiliación Vencida",
                cls: "red"
            },
            {
                id: "A4",
                name: "Solo Pautante",
                cls: "grey"
            },
            {
                id: "A5",
                title: "Desafiliado Pautante",
                name: "Desafiliado Pautante",
                cls: "grey"
            },
        ]
        for (const it of status) {
            let { name, title } = it;           

            view.push(
                <TagStatus className={it.cls} key={name}>
                    <span className="label">{`${title || name}: `}</span>
                    <span className="value">{groups[name] ? groups[name].length : 0}</span>
                </TagStatus>
            )
        }
        return <Container>{view}</Container>

    }
    const getData = async (page) => {
        setPage(page)
        setLoading(true)
        const service = getService("companies");
        await service.find({
            paginate: {
                default: 10000,
                max: 10000,
            },
            query: {
                $limit: MAX,
                $skip: page
            }
        })
            .then(async (response) => {
                let { total } = response;
                let increment = page + MAX;
                setPage(total)
                setPage(increment)
                setData(data => data.concat(...response.data))
                if (increment < total) {
                    await getData(increment)
                } else {
                    setLoading(false)
                }
            })
    }

    return <div>
        {!loading ? getGrops() :
            <Container>
                <span>Cargando, espere por favor...</span>
            </Container>
        }
    </div>
}
const CustomToolBar = () => (<>
    <CustomStatus />
</>)
const CompanyFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Razón social" source="name" alwaysOn />
        <ReferenceInput perPage={1000} alwaysOn label="Grupo" source="group_id" reference="group-options">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput alwaysOn label="Estado de Afiliado" source="affiliate_status_id" reference="affiliate-status-options">
            <SelectInput optionText="name" />
        </ReferenceInput>

    </Filter>
);

const AffiliateStatus = ({ record, ...rest }) => {

    return record && record.affiliate_status
        ? (<Link to={`/affiliate-status-options/${record.affiliate_status.id}`} >
            <TextField source="affiliate_status.name" record={record} {...rest} />
        </Link>)
        : <Link to={`companies/${record.id}`} >Asignar</Link>;
}


AffiliateStatus.defaultProps = {
    label: 'Estado de afiliación',
    addLabel: true
};

const CompanyList = (props) => {
    return (
        <>
            <CustomToolBar />
            <List {...props}
                exporter={false}
                filters={<CompanyFilter />} >
                <Datagrid>
                    <TextField source="id" label="id" />
                    <TextField source="name" label="Razón social" />
                    <TextField source="nit" label="Nit" />
                    <ReferenceField label="Grupo" source="group_id" reference="group-options">
                        <TextField source="name" />
                    </ReferenceField>
                    <AffiliateStatus />
                    <TextField source="address" label="Dirección" />
                    <TextField source="mobile_phone_number" label="Celular" />
                    <TextField source="fix_phone_number" label="Telefono" />
                    <EditButton label="Editar" />
                    <ShowButton label="Ver" />
                </Datagrid>
            </List>

        </>
    )
};

export default CompanyList;