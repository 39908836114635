import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { UserTitle } from "./";
const choices = [
  { id: "true", description: "Activo" },
  { id: "false", description: "Inactivo" },
];
export default class UsersEdit extends Component {
  render() {
    return (
      <Edit title={<UserTitle />} {...this.props}>
        <SimpleForm>
          <Grid container spacing={8}>
            <Grid item xs={2}>
              <DisabledInput label="Id" source="id" />
              <TextInput source="first_name" label="Nombres" />
              <TextInput source="phone_number" label="Telefono" />
            </Grid>
            <Grid item xs={2}>
              <TextInput source="email" label="Correo" />
              <TextInput source="last_name" label="Apellidos" />
              <SelectInput
                fullWidth
                source="active"
                choices={choices}
                optionText="description"
                optionValue="id"
                label="Estado"
              />
              <br></br>
            </Grid>
            <Grid item xs={4}>
              <div>
                <ReferenceInput
                  fullWidth
                  label="Empresa"
                  source="company_id"
                  reference="companies"
                  sort={{
                    field: "name",
                    order: "ASC",
                  }}
                  perPage={50000}
                  filterToQuery={(searchText) => ({ name: searchText })}
                >
                  <AutocompleteInput optionText="name" fullWidth />
                </ReferenceInput>
              </div>
              <div>
                <ReferenceInput
                  label="Rol de empresa"
                  source="company_role_id"
                  reference="company-role-options"
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </div>
            </Grid>
            <Grid item xs={4}>
              <h4>Datos de la compañia</h4>
              <DisabledInput label="Nombre" source="company.name" />
              <DisabledInput
                label="Telegono movil"
                source="company.fix_phone_number"
              />
              <DisabledInput
                label="Telefono fijo"
                source="company.mobile_phone_number"
              />
              <DisabledInput label="Dirección" source="company.address" />
              <TextInput
                fullWidth
                type="password"
                placeholder="••••••••"
                label="Contraseña"
                source="password"
              />
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
