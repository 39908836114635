import React from 'react';
import {
    Create, SimpleForm, TextInput, ReferenceInput,
    SelectInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';


const BuyCamacolCreate = (props) => {
  
    return (
        <Create title='Compra camacol' {...props}>
            <SimpleForm /* validate={validateBuyCamacolFields} */>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="name" label="Nombre" fullWidth={true} />
                        <ReferenceInput source="state_id" label="Estado" reference="locations-states" fullWidth={true} >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
}

export default BuyCamacolCreate;