import React, { Component } from 'react';
import {
    List, Datagrid, TextField, Filter,
    EditButton, TextInput, Link
} from 'react-admin';

const CompanyRoleField = ({ record, ...rest }) => {
    return record && record.company_role_id
        ? (<Link to={`/company-role-options/${record.company_role_id}`} >
            <TextField source="company_role.name" record={record} {...rest} />
        </Link>)
        : <Link to={`user/`} >Asignar</Link>;
}


const FullNameField = ({ source, record = {} }) => {
    return (
        `${record.first_name} ${record.last_name}`
    );
};

const ActiveFied = ({ source, record = {} }) => {
    return (
        record.active === 'true' ? `Activo` : `Inactivo`
    );
};

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput style={{
            width: 200
        }} label="Buscar por Nombre o Email" source="name" alwaysOn />
    </Filter>
);

ActiveFied.defaultProps = { label: 'Estado' };

FullNameField.defaultProps = { label: 'Nombre' };

export default class UsersList extends Component {
    render() {
        return (
            <List
                exporter={false}
                {...this.props} filters={<UserFilter />}>
                <Datagrid>
                    <TextField label="Id" source="id" title="Listado de usuarios" />
                    <FullNameField />
                    <TextField source="email" label="Correo" />
                    <TextField source="phone_number" label="Telefono" />
                    <TextField source="company.name" label="Empresa perteneciente" />
                    <TextField source="role" label="Rol" />
                    <CompanyRoleField />
                    <ActiveFied />
                    <EditButton />
                </Datagrid>
            </List>
        )
    }
};