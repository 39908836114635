import feathers from "@feathersjs/feathers";
import restFeathers from "@feathersjs/rest-client";
import feathersAuthClient from "@feathersjs/authentication-client";
import { URL_BASE_API } from "../constants";

const appFeathers = feathers();
const restClient = restFeathers(URL_BASE_API);
const authClient = feathersAuthClient({
  header: "Authorization",
  path: "/authentication",
  jwtStrategy: "jwt",
  entity: "user",
  service: "users",
  storage: window.localStorage,
});

appFeathers.configure(restClient.fetch(window.fetch.bind(window)));
appFeathers.configure(authClient);

export const companiesService = appFeathers.service("companies");
export const users = appFeathers.service("users");
export const companiesContact = appFeathers.service("companies-contacts");
export const locationsCitiesService = appFeathers.service("locations-cities");
export const buyCamacolService = appFeathers.service("buy-camacol");
export const segmentationsService = appFeathers.service("segmentation-options");
export const companiesSegmentationsService = appFeathers.service(
  "company-segmentation"
);
export const systemVariablesService = appFeathers.service("configurations");
export const getService = (service) => appFeathers.service(service);

export const constructionProceduresService = appFeathers.service( "construction-procedures"
)
export const upliftingActivityResources = appFeathers.service("uplifting-activity-resources")
export const menuService = appFeathers.service( "menu");


export const lands = appFeathers.service("lands");
export const landsAlignments = appFeathers.service("lands-alignments");
export const landsBuildability = appFeathers.service("lands-buildability");




export default appFeathers;
