import React from 'react';
import {
    List, Datagrid, TextField,
    EditButton, DeleteButton,
} from 'react-admin';


const ImageField = ({ record }) => (
    record.icon_path ? <img src={`https://micamacol.s3.amazonaws.com/${record.icon_path}`} className="imgList"  alt="img" /> : null
)

const ConstructionProceduresList = (props) => {

    return (
        <List title="Procedimientos de Construcción" {...props}
        // filters={<Filters />}
        >
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField label="Nombre de la ciudad" source="city_name" />
                <TextField label="Posición" source="position" />
                <ImageField source="icon_path" className="imgList" label="Icono" />
                <TextField label="Observación" source="observation" />
                <TextField label="Correo del contacto" source="contact_email" />
                <TextField label="nombre de la ciudad" source="city_name" />
                <TextField label="Estado" source="status" />
                {/* <FontField /> */}
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default ConstructionProceduresList;

