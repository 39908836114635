import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput} from 'react-admin';
import { CompanyRoleOptionsTitle } from './';

export default class CompanyRoleOptionsEdit extends Component{
    render(){
        return(
            <Edit  title={<CompanyRoleOptionsTitle />} {...this.props}>
                <SimpleForm>
                    <TextInput source="name" />
                </SimpleForm>
            </Edit >
        )
    }
}