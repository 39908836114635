import React, { Component } from 'react';
import { Edit, SimpleForm, SelectInput, ReferenceInput} from 'react-admin';

export default class CompaniesSegmentationsEdit extends Component{
    render(){
        return(
            <Edit   {...this.props} title="Editando la segmentación" >
                <SimpleForm>
                    <ReferenceInput label="Empresa" source="company_id" reference="companies">
                        <SelectInput source="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Segmentación" source="segmentation_id" reference="segmentation-options">
                        <SelectInput source="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Edit >
        )
    }
}