import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, SaveButton, Toolbar, SelectInput } from 'react-admin';
import { SystemVariablesTitle } from './';
import Grid from '@material-ui/core/Grid';

const UserEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const dataState = [
    { value: 'active', description: 'activo' },
    { value: 'inactive', description: 'inactivo' }
];

export default class SystemVariablesEdit extends Component{
    render(){
        return(
            <Edit  title={<SystemVariablesTitle />} {...this.props}>
                <SimpleForm toolbar={<UserEditToolbar />}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth  source="description" />
                            <TextInput fullWidth  source="key" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextInput fullWidth  source="value" />
                            <SelectInput label="Estado" choices={dataState} optionText="description" source="status" optionValue="value" fullWidth={true} /><br></br>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}