import React, { Component } from 'react';
import { Edit, SimpleForm, ReferenceInput, SelectInput } from 'react-admin';
import { CompaniesEconomicActivityTitle } from './';

export default class CompaniesEconomicActivityEdit extends Component {
    render() {
        return (
            <Edit {...this.props} title={<CompaniesEconomicActivityTitle />}>
                <SimpleForm>
                    <ReferenceInput label="Empresa" source="company_id" reference="companies">
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Edit>
        )
    }
}