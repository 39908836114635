import React,{ Component } from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField, EmailField, RichTextField, UrlField } from 'react-admin';
import { CompaniesContactShowTitle } from './';
import { Grid } from '@material-ui/core';

const FullNameField = ({ source, record = {} }) => {
    return (
        `${record.first_name} ${record.last_name}`
    );
};

FullNameField.defaultProps = {label:"Nombre del contacto", addLabel: true};

export default class CompaniesContactShow extends Component{
    render(){
        return(
            <Grid container spacing={8} >
                <Grid item xs={6}>
                        <Show {...this.props} title={<CompaniesContactShowTitle/>}>
                            <SimpleShowLayout>
                                {/* <h3>Datos del contacto</h3> */}
                                <ReferenceField label="Departamento" source="contact_deparment_id" reference="contact-deparment-options">
                                    <TextField source="name" />
                                </ReferenceField>
                                <FullNameField label="Nombre"/>
                                <EmailField source="email" label="Email" />
                                <TextField source="phone_number" label="Telefono" />
                                <TextField source="mobile_phone_number" label="Celular" />
                                <ReferenceField label="Empresa" source="company_id" reference="companies">
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField label="Posición" source="contact_position_id" reference="contact-position-options">
                                    <TextField source="name" />
                                </ReferenceField>
                                <RichTextField source="notes"  label="Notas"/>
                            </SimpleShowLayout>
                        </Show>
                        </Grid>
                        <Grid item xs={6}>
                        <Show {...this.props} actions={false} title=' '>
                            <SimpleShowLayout>
                                <h4 style={{"margin-bottom": "-10px"}}>Datos de la empresa</h4>
                                <TextField source="company.id" label="id" />
                                <ReferenceField label="Empresa" source="company_id" reference="companies">
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField label="Grupo" source="company.group_id" reference="group-options">
                                    <TextField source="name" />
                                </ReferenceField>
                                <TextField source="company.name" label="Nombre" />
                                <TextField source="company.address" label="Dirección" />
                                <TextField source="company.fix_phone_number" label="Telefono" />
                                <TextField source="company.mobile_phone_number" label="Celular" />
                                <UrlField source="company.website" label="Pagina web" />
                            </SimpleShowLayout>
                        </Show>
                </Grid>                    
            </Grid>

        )
    }
};