import React, { Component } from 'react';
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, LongTextInput } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { CompaniesContactTitle } from './';

const choices = [
    { value: 'true', description: 'Si' },
    { value: 'false', description: 'No' },
];

export default class CompaniesContactEdit extends Component {
    render() {
        return (
            <Edit {...this.props} title={<CompaniesContactTitle />} actions={false}>
                <SimpleForm>
                    <Grid container spacing={16}>
                        <Grid item xs={6}>
                            <TextInput source="first_name" label="Nombres" fullWidth /><br></br>
                            <TextInput source="last_name" label="Apellidos" fullWidth /><br></br>
                            <TextInput source="email" label="Correo" fullWidth /><br></br>
                            <TextInput source="mobile_phone_number" label="Celular" fullWidth /><br></br>
                            <TextInput source="phone_number" label="Telefono" fullWidth /><br></br>
                        </Grid>
                        <Grid item xs={6}>
                            <ReferenceInput label="Departamento" source="contact_deparment_id" reference="contact-deparment-options" fullWidth>
                                <SelectInput source="name" />
                            </ReferenceInput><br></br>
                            <ReferenceInput label="Empresa" source="company_id" reference="companies" fullWidth>
                                <SelectInput source="name" />
                            </ReferenceInput><br></br>
                            <ReferenceInput label="Posición de contacto" source="contact_position_id" reference="contact-position-options" fullWidth>
                                <SelectInput source="name" />
                            </ReferenceInput>
                            <SelectInput source="show_affiliates_directory" label="Mostar a todos los usuarios" choices={choices} optionText="description" optionValue="value" fullWidth />
                            <LongTextInput source="notes" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}