import React from 'react';
import { Admin, Resource, Login } from 'react-admin';
import { restClient, authClient } from 'ra-data-feathers';
import feathers from './connections/feathers';
import { Layout } from './components/layout';


// import { dashboard } from './components/dashboard';
import { UsersList, UsersEdit, UsersCreate } from './components/users';
import { CompanyList, CompanyEdit, CompanyShow, CompanyCreate } from './components/companies';
import { CompaniesContactCreate, CompaniesContactList, CompaniesContactEdit, CompaniesContactShow } from './components/companies-contacts'
import { CompaniesEconomicActivityCreate, CompaniesEconomicActivityList, CompaniesEconomicActivityEdit } from './components/company-economic-activity';
import { ContactPositionOptionsCreate, ContactPositionOptionsList, ContactPositionOptionsEdit } from './components/contact-position-options';
import { ContactDeparmentOptionsCreate, ContactDeparmentOptionsEdit, ContactDeparmentOptionsList } from './components/contact-department-options';
import { AffiliateStatusOptionsCreate, AffiliateStatusOptionsEdit, AffiliateStatusOptionsList } from './components/affiliate-status-options';
import { SegmentationsOptionsCreate, SegmentationsOptionsEdit, SegmentationsOptionsList } from './components/segmentations-options';
import { CompaniesSegmentationsCreate, CompaniesSegmentationsEdit, CompaniesSegmentationsList } from './components/companies-segmentation';
import { GroupsOptionsCreate, GroupsOptionsEdit, GroupsOptionsList } from './components/group-options';
import { CompanyRoleOptionsCreate, CompanyRoleOptionsList, CompanyRoleOptionsEdit } from './components/company-role-options';
import { BuyCamacolList, BuyCamacolCreate, BuyCamacolEdit } from './components/buy-camacol';
import { LandsList, LandsCreate, TabsEdits } from './components/lands';
import Categories from './components/categories/list';
import CategoryEdit from './components/categories/edit';
import CategoryCreate from './components/categories/create';

import CityList from "./components/cities/list";
import CityEdit from "./components/cities/edit";
import CityCreate from "./components/cities/create";

import StateList from "./components/states/list";
import StateEdit from "./components/states/edit";
import StateCreate from "./components/states/create";
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import BuildingActivity from "./components/building-activity/list";
import ContactInfo from "./components/contact-info/list";
import ContactInfoCreate from "./components/contact-info/create";
import ContactInfoEdit from "./components/contact-info/edit";
import SystemVariablesList from './components/system-variables/list';
import SystemVariablesEdit from "./components/system-variables/edit"
import { ConstructionProceduresCreate, ConstructionProceduresEdit, ConstructionProceduresList } from './components/construction-procedures';
import { MenuCreate, MenuEdit, MenuList } from './components/menu';
import { MeansCreate, MeansEdit, MeansList } from './components/means';

const authClientOptions = {
  storageKey: 'feathers-jwt',
  authenticate: { strategy: 'local' },
  permissionsKey: 'permissions',
  permissionsField: 'roles',
  redirectTo: '/login',
};

const options = { usePatch: true };

const MyLoginPage = () => (
  <Login
    className="login-page"
    // A random image that changes everyday
    backgroundImage="/images/background.jpg"
  />
);

const App = () => ([
  <Admin
    authProvider={authClient(feathers, authClientOptions)}
    dataProvider={restClient(feathers, options)}
    appLayout={Layout}
    loginPage={MyLoginPage}
  // dashboard={dashboard}
  >
    <Resource name="companies-contacts" create={CompaniesContactCreate} list={CompaniesContactList} edit={CompaniesContactEdit} show={CompaniesContactShow} options={{ label: 'Contactos de empresas' }} />
    <Resource name="company-economic-activity" create={CompaniesEconomicActivityCreate} list={CompaniesEconomicActivityList} edit={CompaniesEconomicActivityEdit} options={{ label: 'Actividades economicas de las empresas' }} />
    <Resource name="company-segmentation" create={CompaniesSegmentationsCreate} edit={CompaniesSegmentationsEdit} list={CompaniesSegmentationsList} options={{ label: 'Segmentaciones de empresas' }} />
    {/*Configuraciones*/}
    <Resource name="affiliate-status-options" create={AffiliateStatusOptionsCreate} edit={AffiliateStatusOptionsEdit} list={AffiliateStatusOptionsList} options={{ label: 'Opciones de estados de afiliaciones' }} />
    {/* <Resource name="economic-activity-options" create={EconomicActivityOptionsCreate} list={EconomicActivityOptionsList} edit={EconomicActivityOptionsEdit} options={{ label: 'Opciones de actividades economicas' }} /> */}
    <Resource name="contact-position-options" create={ContactPositionOptionsCreate} list={ContactPositionOptionsList} edit={ContactPositionOptionsEdit} options={{ label: 'Opciones de posiciones de contactos' }} />
    <Resource name="contact-deparment-options" create={ContactDeparmentOptionsCreate} list={ContactDeparmentOptionsList} edit={ContactDeparmentOptionsEdit} options={{ label: 'Opciones de departamentos' }} />
    <Resource name="segmentation-options" create={SegmentationsOptionsCreate} edit={SegmentationsOptionsEdit} list={SegmentationsOptionsList} options={{ label: 'Opciones de segmentaciones' }} />
    <Resource name="group-options" create={GroupsOptionsCreate} edit={GroupsOptionsEdit} list={GroupsOptionsList} options={{ label: 'Opciones de grupos' }} />
    <Resource name="company-role-options" create={CompanyRoleOptionsCreate} edit={CompanyRoleOptionsEdit} list={CompanyRoleOptionsList} />
    <Resource name="buy-camacol" list={BuyCamacolList} create={BuyCamacolCreate} edit={BuyCamacolEdit} />
    {/* <Resource name="buy-camacol-categories" list={BuyCamacolList} /> */}
    <Resource name="type-affiliates" />
    <Resource name="users" list={UsersList} edit={UsersEdit} create={UsersCreate} options={{ label: 'Usuarios' }} />
    <Resource name="companies" list={CompanyList} edit={CompanyEdit} show={CompanyShow} create={CompanyCreate} options={{ label: 'Empresas' }} />
    <Resource name="lands" list={LandsList} edit={TabsEdits} create={LandsCreate} options={{ label: 'Lotes' }} />

    {/* new Modules */}
    <Resource name="buy-camacol-categories"
      list={Categories}
      edit={CategoryEdit}
      create={CategoryCreate}
    />
    <Resource name="locations-cities"
      list={CityList}
      edit={CityEdit}
      create={CityCreate}
    />
    <Resource name="locations-states"
      list={StateList}
      edit={StateEdit}
      create={StateCreate}
    />
    <Resource
      name="upload-excel-livo"
      list={BuildingActivity}
    />
    <Resource
      name="contact-info"
      list={ContactInfo}
      edit={ContactInfoEdit}
      create={ContactInfoCreate}
    />
    <Resource
      name="configurations"
      list={SystemVariablesList}
      edit={SystemVariablesEdit}
    />
    <Resource
      name="construction-procedures"
      create={ConstructionProceduresCreate}
      list={ConstructionProceduresList}
      edit={ConstructionProceduresEdit}
      />
    <Resource
      name="menu"
      create={MenuCreate}
      list={MenuList}
      edit={MenuEdit}
    />
    <Resource
      name="uplifting-activity-resources"
      create={MeansCreate}
      list={MeansList}
      edit={MeansEdit}
    />
  </Admin>
]
);

export default App;
//