import AffiliateStatusOptionsCreate from './create';
import AffiliateStatusOptionsEdit from './edit';
import AffiliateStatusOptionsIcon from '@material-ui/icons/BubbleChart';
import AffiliateStatusOptionsList from './list';

const AffiliateStatusOptionEditTitle = ({record}) =>{
    return record ? `Estado de afiliacion "${record.name}"` : null 
}

export {AffiliateStatusOptionsCreate,
        AffiliateStatusOptionsEdit, 
        AffiliateStatusOptionsIcon, 
        AffiliateStatusOptionEditTitle, 
        AffiliateStatusOptionsList
    };