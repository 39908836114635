import React from 'react';
import {
    List, Datagrid, TextField, Filter,
    EditButton, TextInput, DeleteButton
} from 'react-admin';

const FontField = ({ record }) => {
    let classField = record.fund === 'true' ? "colorTrue" : "colorFalse"
    record.fund === 'true' ? record.fund = 'Si' : record.fund = 'No';
    return (
        <TextField source="fund" record={record} className={classField} />
    )
}



FontField.defaultProps = {
    label: 'Fondo',
    addLabel: true
};


const Filters = (props) => (
    <Filter {...props} >
        <TextInput label="Nombre" source="name" alwaysOn/>
    </Filter>
);

const BuyCamacolList = (props) => {

    return (
        <List title="Camacol compra camacol" {...props} filters={<Filters />} >
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField label="Nombre" source="name" />
                {/* <FontField /> */}
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default BuyCamacolList;

