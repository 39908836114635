import SegmentationsOptionsCreate from './create';
import SegmentationsOptionsEdit from './edit';
import SegmentationsOptionsList from './list';
import SegmentationsOptionsIcon from '@material-ui/icons/Reorder';

const SegmentationsOptionsEditTitle = ({record}) =>{
    return record ? `Segmentación "${record.name}"` : null 
}

export { SegmentationsOptionsCreate,
        SegmentationsOptionsEdit,
        SegmentationsOptionsEditTitle,
        SegmentationsOptionsList,
        SegmentationsOptionsIcon
    };