import React from "react";
import { Upload, message, Button, Tooltip } from "antd";
import { URL_IMPORT } from "../../constants/";
import styled from "styled-components";
const RoundedButton = styled(Button)`
  border-radius: 20px !important;
  color:#fff;
  width: auto;
  padding: 0px 10px;

  margin:10px;

  background: rgb(255, 255, 255)!important;
  border: 1px solid rgb(223, 223, 223)!important;
  color: rgba(0, 0, 0, 0.8)!important;
`;

/* 
 primaryKey: "lic_nbr",
 model: "agentsContacts"
*/
const FileUploader = ({
  primaryKey,
  model,
  ...props
}) => {
  let options = {
    name: props.name || "excel_file",
    action: props.action || URL_IMPORT,
    headers: {
      authorization: localStorage.getItem("feathers-jwt")
    },
    showUploadList: props.showUploadList || false,
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        if (props.onSubmit) props.onSubmit(info.file);
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  return (
    <>
      <Upload {...options}>
        <Tooltip placement="bottom" title="Import Csv">
          <RoundedButton
            style={
              props.style || {
                borderRadius: "50%"
              }
            }
            type="primary"
          >
            {props.children}
          </RoundedButton>
        </Tooltip>
      </Upload>
    </>
  );
};

export default FileUploader;
