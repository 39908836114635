import React, { useEffect, useState } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  LongTextInput,
  SelectArrayInput,
  AutocompleteInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import {
  locationsCitiesService,
  companiesService,
} from "../../connections/feathers";
import { validateBuyCamacolFields } from "./";

const dataTypes = [
  { value: "Servicios", description: "Servicios" },
  { value: "Insumos", description: "Insumos" },
];

// const dataFund = [
//     { value: 'true', description: 'Si' },
//     { value: 'false', description: 'No' }
// ];

const BuyCamacolCreate = (props) => {
  const [locationsCities, setLocationsCities] = useState();
  const [companies, setCompanies] = useState();

  useEffect(() => {
    locationsCitiesService.find().then((it) => setLocationsCities(it.data));
    companiesService
      .find({ $limit: 100000 })
      .then((it) => setCompanies(it.data));
  });

  return (
    <Create title="Compra camacol" {...props}>
      <SimpleForm validate={validateBuyCamacolFields}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <TextInput source="name" label="Nombre" fullWidth={true} />
            <SelectInput
              label="Tipo"
              choices={dataTypes}
              optionText="description"
              source="type"
              optionValue="value"
              fullWidth
            />
            <ReferenceInput
              label="Categorias"
              source="buy_camacol_category_id"
              reference="buy-camacol-categories"
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput
              source="location_cities"
              choices={locationsCities}
              optionText="name"
              optionValue="id"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteInput
              source="company_id"
              choices={companies}
              optionText="name"
              optionValue="id"
              className="fullWidthAutoComplete"
            />
            <TextInput
              source="contact_full_name"
              label="Nombre de contacto"
              fullWidth={true}
            />
            <TextInput
              source="contact_email"
              label="Correo de contacto"
              type="email"
              fullWidth={true}
            />
            <TextInput
              source="contact_phone"
              label="Telefono del contacto"
              type="email"
              fullWidth={true}
            />
            {/* <SelectInput label="Fondo" choices={dastaFund} optionText="description" source="fund" optionValue="value" fullWidth={true} /><br></br> */}
          </Grid>
        </Grid>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <LongTextInput source="description" label="Descripción" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default BuyCamacolCreate;
