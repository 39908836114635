import React, { Component } from "react";
import {
  Edit,
  SimpleForm,
  DisabledInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleShowLayout,
  CheckboxGroupInput,
} from "react-admin";
import Grid from "@material-ui/core/Grid";
import { CompanyTitle } from "./";
import {
  segmentationsService,
  companiesSegmentationsService,
  companiesService,
} from "../../connections/feathers";
import { S3File } from "../s3/";

export default class CompanyEdit extends Component {
  state = {
    segmentationsOptions: [{ name: "NameSegmentation", id: 0 }],
    segmentationsOptionsActives: [2],
    imagePath: null,
  };

  async fetchData() {
    Promise.all([
      segmentationsService
        .find({ query: { $limit: 1000 } })
        .then((it) => this.setState({ segmentationsOptions: it.data })),

      companiesSegmentationsService
        .find({ query: { company_id: this.props.id, $limit: 1000 } })
        .then((it) =>
          this.setState({
            segmentationsOptionsActives: it.data.map(
              (it) => it.segmentation_id
            ),
          })
        ),

      companiesService
        .get(this.props.id)
        .then((it) => this.setState({ imagePath: it.logo }))
        .catch((error) => console.log(error, "+++++")),
    ]);
  }

  componentDidMount() {
    this.fetchData();
  }

  handleUploadFinish = async (url, id) => {
    await companiesService
      .patch(id, { logo: url })
      .then((it) => this.setState({ imagePath: it.logo }));
  };

  render() {
    const {
      segmentationsOptions,
      segmentationsOptionsActives,
      imagePath,
    } = this.state;
    const buildingActivity = [
      { value: "active", description: "Activo" },
      { value: "unactive", description: "Inactivo" },
    ];
    return (
      <Edit title={<CompanyTitle />} {...this.props}>
        <SimpleForm>
          <Grid container spacing={12}>
            <Grid item xs={4}>
              <DisabledInput label="Id" source="id" />
              <TextInput source="name" label="Razón social" fullWidth />
              <TextInput source="nit" label="Nit" fullWidth />
              <TextInput source="fix_phone_number" label="Telefono" fullWidth />
              <ReferenceInput
                label="Estado de la afiliación"
                source="affiliate_status_id"
                reference="affiliate-status-options"
              >
                <SelectInput optionText="name" labelWidth={10} fullWidth />
              </ReferenceInput>
              {/* <br></br> */}
              <ReferenceInput
                label="Grupo"
                source="group_id"
                reference="group-options"
              >
                <SelectInput optionText="name" labelWidth={5} fullWidth />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <TextInput
                source="mobile_phone_number"
                label="Celular"
                fullWidth
              />
              <TextInput
                source="management_fee"
                label="Cuota de sostenimiento"
                fullWidth
              />
              <TextInput source="address" label="Direccíon" fullWidth />
              <TextInput source="website" label="Sitio web" fullWidth />
              <TextInput
                source="economic_activity"
                label="Actividad economica"
                fullWidth
              />
              <TextInput source="email" type="email" label="Correo" fullWidth />
            </Grid>
            <Grid item xs={4}>
              <SelectInput
                label="Actividad Edificadora"
                choices={buildingActivity}
                optionText="description"
                source="building_activity"
                optionValue="value"
                fullWidth
              />
              <CheckboxGroupInput
                source="companies_segmentations_ids"
                label="Segmentaciones"
                choices={segmentationsOptions}
                defaultValue={segmentationsOptionsActives}
              />
              <S3File
                idComponent="btn-send-buy-camacol-s3"
                path="companies"
                finalPath="logo"
                handleUploadFinish={this.handleUploadFinish}
                id={this.props.id}
              />
              <Show {...this.props} actions={false} className="showEdit">
                <SimpleShowLayout className="showEditSimpleLayout">
                  {imagePath ? (
                    <img
                      src={`https://micamacol.s3.amazonaws.com/${imagePath}`}
                      className="custom-img-field"
                      alt="img"
                    />
                  ) : null}
                </SimpleShowLayout>
              </Show>
            </Grid>
          </Grid>
        </SimpleForm>
      </Edit>
    );
  }
}
