import React from 'react';
import {
    Edit, SimpleForm, TextInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
const BuyCamacolCreate = (props) => {

    return (
        <Edit title={"Editar Categoria"} {...props}>
            <SimpleForm /* validate={validateBuyCamacolFields} */>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="name" label="Nombre" fullWidth={true} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

export default BuyCamacolCreate;