import React, { Component } from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput} from 'react-admin';


export default class CompaniesSegmentationsCreate extends Component{
    render(){
        return(
            <Create {...this.props} title="Crear una nueva segmentación en una empresa">
                <SimpleForm>
                    <ReferenceInput label="Empresa" source="company_id" reference="companies">
                        <SelectInput source="name" />
                    </ReferenceInput>
                    <ReferenceInput label="Segmentación" source="segmentation_id" reference="segmentation-options">
                        <SelectInput source="name" />
                    </ReferenceInput>
                </SimpleForm>
            </Create>
        )
    }
}