import React, { useEffect, useState } from 'react';
import {
    Edit, SimpleForm, TextInput, ReferenceInput, Show, SimpleShowLayout,
    SelectInput, LongTextInput, SelectArrayInput, AutocompleteInput, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { locationsCitiesService, companiesService, buyCamacolService } from '../../connections/feathers';
import { S3File } from '../s3/';
import { validateBuyCamacolFields } from './'


const dataTypes = [
    { value: 'Servicios', description: 'Servicios' },
    { value: 'Insumos', description: 'Insumos' }
];

// const dataFund = [
//     { value: 'true', description: 'Si' },
//     { value: 'false', description: 'No' }
// ];

const BuyCamacolTitle = ({ record }) => {
    return <span>Compra camacol {record ? `"${record.name}"` : ''}</span>;
};

const BuyCamacolCreate = (props) => {
    const [locationsCities, setLocationsCities] = useState([{ name: 'Barranquilla', id: 1 }]);
    const [companies, setCompanies] = useState([{ name: 'Empresa', id: 0 }]);
    const [imagePath, setImagePath] = useState();

    useEffect(() => {
        locationsCitiesService.find().then(it => setLocationsCities(it.data))
        companiesService.find({ $limit: 100000 }).then(it => setCompanies(it.data))
        buyCamacolService.find({ query: { id: props.id } }).then(it => setImagePath(it.data[0].image_path))
    }, [props.id]);

    const handleUploadFinish = async (url, id) => {
        await buyCamacolService.patch(id, { image_path: url }).then(it => setImagePath(it.image_path))
    }

    return (
        <Edit title={<BuyCamacolTitle />} {...props}>
            <SimpleForm validate={validateBuyCamacolFields}>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="name" label="Nombre" fullWidth={true} />
                        <SelectInput label="Tipo" choices={dataTypes} optionText="description" source="type" optionValue="value" fullWidth={true} /><br></br>
                        <ReferenceInput label="Categorias" source="buy_camacol_category_id" reference="buy-camacol-categories" fullWidth={true}>
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <S3File idComponent="btn-send-buy-camacol-s3" path="buy-camacol" finalPath="images" handleUploadFinish={handleUploadFinish} id={props.id} />
                        <Show {...props} actions={false} className="showEdit">
                            <SimpleShowLayout className="showEditSimpleLayout">
                                <Grid item xs={12}>
                                    <img src={`https://micamacol.s3.amazonaws.com/${imagePath}`} className="custom-img-field" alt="img" />
                                </Grid>
                            </SimpleShowLayout>
                        </Show>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <SelectInput label="Fondo" choices={dataFund} optionText="description" source="fund" optionValue="value" fullWidth={true} /><br></br> */}
                        <SelectArrayInput defaultValues={[{ name: 'Barranquilla', id: 1 }]} source="location_cities" choices={locationsCities} optionText="name" optionValue="id" fullWidth={true} label="Ciudades" />
                        <Show {...props} actions={false} className="showEdit">
                            <SimpleShowLayout className="showEditSimpleLayout">
                                <ReferenceArrayField label="Ciudades" reference="locations-cities" source="locations_cities_ids">
                                    <SingleFieldList>
                                        <ChipField source="name" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </SimpleShowLayout>
                        </Show>
                        <AutocompleteInput source="company_id" choices={companies} optionText="name" optionValue="id" className="fullWidthAutoComplete" />
                        <TextInput source="contact_full_name" label="Nombre de contacto" fullWidth={true} />
                        <TextInput source="contact_email" label="Correo de contacto" type="email" fullWidth={true} />
                        <TextInput source="contact_phone" label="Telefono del contacto" type="email" fullWidth={true} />
                        <LongTextInput source="description" />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

export default BuyCamacolCreate;