import React, { useEffect, useState } from 'react';
import {
    Edit, SimpleForm, TextInput, Show, SimpleShowLayout,
    SelectInput, Labeled
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { S3File } from '../s3';
import { constructionProceduresService } from '../../connections/feathers';

const dataState = [
    { value: 'active', description: 'activo' },
    { value: 'inactive', description: 'inactivo' }
];

const ConstructionProceduresCreate = (props) => {
    const [imagePath, setImagePath] = useState();
    const [pdfPath, setPdfPath] = useState();
    const [filePath, setFilePath] = useState();

    useEffect(() => {
        constructionProceduresService.find({ query: { id: props.id } }).then(it => setImagePath(it.data[0].icon_path))
        constructionProceduresService.find({ query: { id: props.id }}).then(it => setPdfPath(it.data[0].preview_pdf_path))
        constructionProceduresService.find({ query: { id: props.id }}).then(it => setFilePath(it.data[0].download_file_path))
    }, [props.id]);

    const handleUploadFinish = async (url, id) => {
        await constructionProceduresService.patch(id, { icon_path: url }).then(it => setImagePath(it.icon_path))
    }

    const handleUploadFinishPdf = async (url, id) => {
        await constructionProceduresService.patch(id, { preview_pdf_path: url }).then(it => setPdfPath(it.preview_pdf_path))
    }

    const handleUploadFinishFile = async (url, id) => {
        await constructionProceduresService.patch(id, { download_file_path: url }).then(it => setFilePath(it.download_file_path))
    }

    console.log({imagePath})
    return (
        <Edit title={"Editar Procedimiento de Construcción"} {...props}>
            <SimpleForm>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <TextInput source="city_name" label="Nombre de la ciudad" fullWidth={true} />
                        <Labeled label="Sube aqui la imagen">
                            <S3File idComponent="btn-send-buy-camacol-s3_1" path="icon_path" finalPath="images" handleUploadFinish={handleUploadFinish} id={props.id} />
                        </Labeled>
                        <Show {...props} actions={false} className="showEdit">
                            <SimpleShowLayout className="showEditSimpleLayout">
                                <Grid item xs={12}>
                                    <img src={`https://micamacol.s3.amazonaws.com/${imagePath}`} className="custom-img-field" alt="img" />
                                </Grid>
                            </SimpleShowLayout>
                        </Show>
                        <Labeled label="Sube aquí el archivo vista previa de PDF">
                            <S3File contentDisposition="inline" nameBtn="Subir Archivo" idComponent="btn-send-buy-camacol-s3_2" path="preview_pdf_path" finalPath="files" handleUploadFinish={handleUploadFinishPdf} id={props.id} />
                        </Labeled>
                        <Show {...props} actions={false} className="showEdit">
                            <SimpleShowLayout className="showEditSimpleLayout">
                                <Grid item xs={12}>
                                    <a href={`https://micamacol.s3.amazonaws.com/${pdfPath}`}>{pdfPath}</a>
                                </Grid>
                            </SimpleShowLayout>
                        </Show>
                    </Grid>
                    <Grid item xs={6}>
                        <Labeled label="Sube aquí el archivo que será descargado">
                            <S3File nameBtn="Subir Archivo" idComponent="btn-send-buy-camacol-s3_3" path="download_file_path" finalPath="files" handleUploadFinish={handleUploadFinishFile} id={props.id} />
                        </Labeled>
                        <Show {...props} actions={false} className="showEdit">
                            <SimpleShowLayout className="showEditSimpleLayout">
                                <Grid item xs={12}>
                                    <a href={`https://micamacol.s3.amazonaws.com/${filePath}`}>{filePath}</a>
                                </Grid>
                            </SimpleShowLayout>
                        </Show>
                        <TextInput source="observation" label="observación" fullWidth={true} />
                        <TextInput source="contact_email" label="Correo" fullWidth={true} />
                        <SelectInput label="Estado" choices={dataState} optionText="description" source="status" optionValue="value" fullWidth={true} /><br></br>
                        <TextInput source="position" label="Posición" fullWidth={true} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}

export default ConstructionProceduresCreate;