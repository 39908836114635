import ContactDeparmentOptionsCreate from './create';
import ContactDeparmentOptionsList from './list';
import ContactDeparmentOptionsEdit from './edit';
import ContactDeparmentOptionsIcon from '@material-ui/icons/Layers';

const ContactDeparmentOptionsTitle = ({record}) =>{
    return record ? `Departamento "${record.name}"` : null 
}

export {ContactDeparmentOptionsCreate,
        ContactDeparmentOptionsList, 
        ContactDeparmentOptionsEdit, 
        ContactDeparmentOptionsTitle,
        ContactDeparmentOptionsIcon
    };