import React from 'react';
import {
    List, Datagrid, TextField,
    EditButton, DeleteButton,
} from 'react-admin';

// const Filters = (props) => (
//     <Filter {...props} >
//         <TextInput label="Nombre" source="name" alwaysOn />
//     </Filter>
// );

const MeansList = (props) => {

    return (
        <List title="Procedimientos de Construcción" {...props}>
            <Datagrid>
                <TextField label="Id" source="id" />
                <TextField label="Descripción" source="description" />
                <TextField label="Tipo" source="type" />
                <TextField label="Posición" source="position" />
                <TextField label="Estado" source="status" />
                <EditButton label={false} />
                <DeleteButton label={false} />
            </Datagrid>
        </List>
    )

};

export default MeansList;

