import React, { Component } from 'react';
import {
    Edit, SimpleForm, DisabledInput, TextInput, ReferenceInput, SelectInput, LongTextInput
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { LandTitle } from './'
import { segmentationsService, companiesSegmentationsService, companiesService } from '../../connections/feathers';
import { validateLandsFields } from './'

export default class LandsEdit extends Component {

    state = {
        segmentationsOptions: [{ name: 'NameSegmentation', id: 0 }],
        segmentationsOptionsActives: [2],
        imagePath: null
    }

    async fetchData() {

        Promise.all([

            segmentationsService.find({ query: { $limit: 1000 } })
                .then(it => this.setState({ segmentationsOptions: it.data })),

            companiesSegmentationsService.find({ query: { company_id: this.props.id, $limit: 1000 } })
                .then(it => this.setState({ segmentationsOptionsActives: it.data.map(it => (it.segmentation_id)) })),

            companiesService.get(this.props.id)
                .then(it => this.setState({ imagePath: it.logo }))
                .catch(error => console.log(error, '+++++'))

        ])

    }

    componentDidMount() {
        this.fetchData()
    }

    handleUploadFinish = async (url, id) => {
        await companiesService.patch(id, { logo: url }).then(it => this.setState({ imagePath: it.logo }))
    }

    render() {
        return (
            <Edit title={<LandTitle />} {...this.props}>
                <SimpleForm validate={validateLandsFields}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <DisabledInput label="Id" source="id" fullWidth />
                            <TextInput source="name" label="Dirección" fullWidth />
                            <TextInput source="real_estate_registration" label="Matricula Inmobiliaria" fullWidth />
                            <TextInput source="lat" label="Latitud" fullWidth />
                            <TextInput source="long" label="Longitud" fullWidth />
                            <TextInput source="area_m2" label="Metros 2" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ReferenceInput perPage={5000} sort={{ field: "name", order: "ASC" }} label="Afiliado" source="affiliate_id" reference="companies" fullWidth>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <ReferenceInput label="Ciudad" source="city_id" reference="locations-cities" fullWidth>
                                <SelectInput optionText="name" />
                            </ReferenceInput>
                            <TextInput source="neighborhood" label="Barrio" fullWidth />
                            <TextInput source="price" label="Precio" fullWidth />
                            <LongTextInput source="description" label="Descripción" />
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }

}
